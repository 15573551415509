import style from "./Root.module.scss"
import cs from "classnames"
import { TModeRoot } from "Types/Settings"
import { useIndexWithApi } from "Hooks/useIndexWithApi"
import { IQuGenTok, IQuGenToksObjkts } from "Types/queries/GenToksObjkts"
import { GenerativeToken } from "Types/entities/GenerativeToken"
import { Qu_genTokenEntireCollection } from "Queries/Projects"
import { RarityCyclerCarousel } from "./Carousel"
import { useMemo } from "react"
import { computeScoreFxhashNative } from "Utils/rarity"

interface ISettings {
  project_id: string
  delay: string
  load_iframe: "0" | "1"
}
export const RarityCyclerRoot: TModeRoot<ISettings> = ({ settings }) => {
  const { data, loading } = useIndexWithApi<IQuGenTok, GenerativeToken>(
    Qu_genTokenEntireCollection,
    {
      variables: {
        id: parseInt(settings.project_id),
      },
    },
    input => {
      // filter unsigned editions
      const tok = { ...input.generativeToken }
      tok.entireCollection = tok.entireCollection!.filter(
        gentk => gentk.assigned
      )
      return tok
    }
  )

  // order gentks by iteration #
  const gentks = useMemo(() => {
    const out = data?.entireCollection
      ? [...data.entireCollection].sort((a, b) => a.iteration! - b.iteration!)
      : undefined
    return out ? computeScoreFxhashNative(out) : undefined
  }, [data])

  return loading || !gentks || !data ? (
    <div>loading</div>
  ) : (
    <div className={cs(style.root)}>
      <div className={cs(style.carousel_wrapper)}>
        <RarityCyclerCarousel
          token={data}
          gentks={gentks}
          intervalMs={parseInt(settings.delay)}
          loadIframes={settings.load_iframe === "1"}
        />
      </div>
    </div>
  )
}
