import style from "./Avatar.module.scss"
import cs from "classnames"
import { ipfsGatewayUrl } from "Utils/ipfs"

interface Props {
  uri: string | null | undefined
  className?: string
}

export function Avatar({ uri, className }: Props) {
  const url = ipfsGatewayUrl(uri)
  return (
    <div
      className={cs(style.container, className)}
      style={{
        backgroundImage: url && `url(${url})`,
      }}
    />
  )
}
