import React, { memo, useEffect, useState } from "react"
import { TimeoutTracked } from "Utils/time"

interface TimeoutCounterProps {
  refTimeout?: React.MutableRefObject<TimeoutTracked | null>
  refreshEveryMs?: number
}

const _TimeoutCounter = ({
  refTimeout,
  refreshEveryMs = 1000,
}: TimeoutCounterProps) => {
  const [counter, setCounter] = useState<number>(0)

  useEffect(() => {
    const interval = setInterval(() => {
      if (refTimeout?.current) {
        const remainingTime = refTimeout.current.getRemainingTime()
        setCounter(remainingTime)
      }
    }, refreshEveryMs)
    return () => {
      clearInterval(interval)
    }
  }, [refTimeout, refreshEveryMs])
  return <span>{(counter / 1000).toFixed(1)}s</span>
}

export const TimeoutCounter = memo(_TimeoutCounter)
