import { ApolloClient, useApolloClient } from "@apollo/client"
import { Qu_users } from "Queries/user"
import { IssuerContractState } from "Services/Indexing/ContractHandlers/IssuerHandler"
import { User } from "Types/entities/User"

/**
 * Enchances a list of mint operations with their minter details (fetched
 * using the fxhash API in the first place)
 */
export async function enhanceIssuerStateWithAuthors(
  state: IssuerContractState,
  client: ApolloClient<object>
): Promise<IssuerContractState> {
  // build a list of tezos addresses we have to fetch to enhance the data
  const addressesToFetch: string[] = []

  for (const projectId in state.projects) {
    for (const mint of state.projects[projectId].mints) {
      // it means that it was already fetched and null, we ignore
      if (mint.sender === false) continue
      // if no sender, we ask for it
      if (!mint.sender && !addressesToFetch.includes(mint.minterAddress)) {
        addressesToFetch.push(mint.minterAddress)
      }
    }
  }

  // make a call to get the users matching with the address
  const { data, error } = await client.query({
    query: Qu_users,
    variables: {
      filters: {
        id_in: addressesToFetch,
      },
    },
  })

  // map query response with the results object
  if (data && !error) {
    const users: User[] = data.users
    for (const projectId in state.projects) {
      for (const mint of state.projects[projectId].mints) {
        if (mint.sender === false) continue
        // look into the addresses to see if there is a match
        const user = users.find(user => user.id === mint.minterAddress)
        // either set the user, or set false because it could not be fetched
        mint.sender =
          user ||
          ({
            id: mint.minterAddress,
          } as User)
      }
    }
  }

  return state
}
