import {
  HTMLInputTypeAttribute,
  InputHTMLAttributes,
  ReactNode,
  RefObject,
  useCallback,
  useState,
} from "react"
import { FxParamOptionsMap, FxParamType } from "../types"
import classes from "./Controller.module.scss"
import cx from "classnames"
import { BaseInput, BaseInputProps } from "../BaseInput"
import cs from "classnames"

/*
 * Providing a name starting or ending with `search` prevents
 * 1Password extension to appear in the input fields
 * https://1password.community/discussion/comment/606453/#Comment_606453
 */
export function BaseParamsInput(props: BaseInputProps) {
  const { id } = props
  return (
    <BaseInput name={`${id}-params-search`} autoComplete="off" {...props} />
  )
}

export type FxParamInputChangeHandler = (e: any) => void
export type FxParamControllerLayout = "default" | "invert" | "box"
export type FxParamControllerSize = "normal" | "large"

export interface ControllerProps {
  label?: string
  id?: string
  description?: string
  children: ReactNode
  layout?: FxParamControllerLayout
  size?: FxParamControllerSize
  className?: string
  inputContainerProps?: {
    ref: RefObject<HTMLDivElement>
  }
}

export function Controller(props: ControllerProps) {
  const [labelEllipsis, setLabelEllipsis] = useState(true)
  const {
    id,
    label,
    layout = "default",
    size,
    className,
    inputContainerProps,
    description,
  } = props
  const handleToggleEllipsis = useCallback(
    () => setLabelEllipsis(old => !old),
    []
  )
  return (
    <div
      className={cx(
        classes.controller,
        classes[layout],
        classes[`size-${size}`],
        className
      )}
    >
      <div className={classes.labelContainer}>
        <>
          {id && (
            <label
              className={cs({
                [classes.ellipsis]: labelEllipsis,
              })}
              title={label}
              htmlFor={id}
              onClick={handleToggleEllipsis}
            >
              {label || id}
            </label>
          )}
          {description && <p>{description}</p>}
        </>
      </div>
      <div className={classes.inputContainer} {...inputContainerProps}>
        {props.children}
      </div>
    </div>
  )
}

export interface HTMLInputControllerProps {
  id: string
  value: string
  onChange: FxParamInputChangeHandler
  type: HTMLInputTypeAttribute
  inputProps?: InputHTMLAttributes<HTMLInputElement | HTMLSelectElement>
  classNameController?: string
  className?: string
  label?: string
  layout?: FxParamControllerLayout
  size?: FxParamControllerSize
}

export type FxParamControllerProps<Type extends FxParamType> = Omit<
  HTMLInputControllerProps,
  "type"
> & {
  value: any
  options?: FxParamOptionsMap[Type]
  onChange: FxParamInputChangeHandler
}

export function HTMLInputController(props: HTMLInputControllerProps) {
  const {
    label,
    id,
    onChange,
    value,
    type,
    classNameController,
    className,
    inputProps = {},
    layout = "default",
    size,
  } = props
  return (
    <Controller
      id={id}
      label={label}
      layout={layout}
      size={size}
      className={classNameController}
    >
      <BaseParamsInput
        className={className}
        type={type}
        id={id}
        onChange={onChange}
        value={value}
        inputSize={size}
        {...inputProps}
      />
    </Controller>
  )
}

export interface HTMLInputControllerWithTextInputProps
  extends HTMLInputControllerProps {
  textInputProps?: InputHTMLAttributes<HTMLInputElement>
}

export function HTMLInputControllerWithTextInput(
  props: HTMLInputControllerWithTextInputProps
) {
  const {
    label,
    id,
    onChange,
    value,
    type,
    className,
    inputProps = {},
    layout = "default",
    size,
    textInputProps,
  } = props
  return (
    <Controller id={id} label={label} layout={layout} size={size}>
      <BaseParamsInput
        className={className}
        type={type}
        id={id}
        onChange={onChange}
        value={value}
        autoComplete="off"
        inputSize={size}
        {...inputProps}
      />
      <BaseParamsInput
        type="text"
        id={`text-${id}`}
        onChange={onChange}
        value={value}
        autoComplete="off"
        inputSize={size}
        {...textInputProps}
      />
    </Controller>
  )
}

export interface HTMLInputControllerWithTextInputProps
  extends HTMLInputControllerProps {
  textInputProps?: InputHTMLAttributes<HTMLInputElement>
}

export function HTMLInputControllerWithText(
  props: HTMLInputControllerWithTextInputProps
) {
  const {
    label,
    id,
    onChange,
    value,
    type,
    className,
    inputProps = {},
    layout = "default",
    size,
    textInputProps,
  } = props
  return (
    <Controller id={id} label={label} layout={layout} size={size}>
      <div className={classes.controllerWithText}>
        <BaseParamsInput
          className={className}
          type={type}
          id={id}
          onChange={onChange}
          value={value}
          autoComplete="off"
          inputSize={size}
          {...inputProps}
        />
        <div className={classes.controllerText}>{value}</div>
      </div>
    </Controller>
  )
}
