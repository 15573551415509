import style from "./ArtworkImageOrFrame.module.scss"
import cs from "classnames"
import { Objkt } from "Types/entities/Objkt"
import { useMemo, useState } from "react"
import { gentkLiveUrl } from "Utils/objkt"
import { Image } from "Components/Image"
import { ArtworkFrame } from "./ArtworkFrame"

/**
 * This component smoothly displays an iframe over the image when the boolean
 * frame is set to true, only showing the iframe when it's loaded
 */
interface Props {
  gentk: Objkt
  frame: boolean
}
export function GentkImageOrFrame({ gentk, frame }: Props) {
  const url = useMemo(() => gentkLiveUrl(gentk), [gentk])
  const [loaded, setLoaded] = useState(false)

  return (
    <div className={cs(style.root)}>
      <Image
        mode="responsive"
        ipfsUri={gentk.displayUri}
        image={gentk.captureMedia}
        alt={gentk.name || ""}
        className={style.image}
      />
      {frame && (
        <ArtworkFrame
          url={url}
          className={cs(style.frame, {
            [style.loaded]: loaded,
          })}
          onLoad={() => setLoaded(true)}
        />
      )}
    </div>
  )
}
