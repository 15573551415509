import { GenerativeToken } from "Types/entities/GenerativeToken"
import css from "./NoParamsStation.module.scss"
import cs from "classnames"
import {
  SharedParamsMinterSettings,
  TSharedParamsMinterEvent,
  addLiveMintingRouteToUrl,
} from "./shared"
import { IUseChannelConnectionReturn } from "Hooks/useChannel"
import { getUserName } from "Utils/user"
import nl2br from "react-nl2br"
import { ArtworkFrame } from "Components/Artwork/ArtworkFrame"
import { useEffect, useMemo, useRef, useState } from "react"
import { gentkUrl } from "Utils/objkt"
import { generateFxHash } from "Utils/hash"
import { Icon } from "Components/UI/Icon"
import { DisplayTezos } from "Components/Display/DisplayTezos"
import { useFetchLiveMintingUrl } from "Hooks/useFetchLiveMintingUrl"
import { useClickOutside } from "Hooks/useClickOutside"
import { BaseButton } from "Components/FxParams/BaseInput"
import { Loader } from "Components/Utils/Loader"
import { QRCode } from "Components/QRCode/QRCode"

interface Props<Events extends string> extends SharedParamsMinterSettings {
  token: GenerativeToken
  connection: IUseChannelConnectionReturn<Events | TSharedParamsMinterEvent>
}
export function NoParamsStation<Events extends string>({
  token,
  connection,
  ...settings
}: Props<Events>) {
  const modalRef = useRef<HTMLDivElement>(null)
  const [showModal, setShowModal] = useState<boolean>(false)

  const { refetch, loading } = useFetchLiveMintingUrl(settings)
  const [finalUrl, setFinalUrl] = useState<string>()

  const [hash, setHash] = useState(generateFxHash())

  const url = useMemo(
    () => gentkUrl(token.generativeUri!, hash, ""),
    [token, hash]
  )

  useEffect(() => {
    connection.broadcast("inputs:update", {
      hash,
      minter: "",
      inputBytes: "",
    })
  }, [hash])

  const price = token.pricingFixed?.price
  console.log(price)

  const toggleModal = () => setShowModal(!showModal)

  const onMintArtwork = async () => {
    toggleModal()

    const route = `/generative/${token.id}`
    const queryParams = {
      fxhash: hash,
    }

    const url = await refetch()
    const finalUrl = addLiveMintingRouteToUrl(url, route, queryParams)
    setFinalUrl(finalUrl)
  }

  const onModalClose = () => {
    toggleModal()
    setFinalUrl(undefined)
  }

  useClickOutside(modalRef, () => {
    if (showModal) onModalClose()
  })

  return (
    <>
      <div className={cs(css.root, { [css.blur]: showModal })}>
        <div className={cs(css.frame)}>
          <ArtworkFrame url={url} />
        </div>
        <div className={cs(css.panel)}>
          <div className={cs(css.header)}>
            <h1>{token.name}</h1>
            <h2>
              <span className={css.by}>by</span> {getUserName(token.author, 12)}
            </h2>
          </div>
          <div className={cs(css.descWrapper)}>
            <div className={cs(css.desc)}>
              {nl2br(token.metadata.description)}
            </div>
          </div>
          <div className={cs(css.buttons)}>
            <button
              className={cs(css.refreshSeed)}
              type="button"
              onClick={() => setHash(generateFxHash)}
            >
              <span>refresh seed</span>
              <Icon icon="fa-solid fa-arrows-rotate" />
            </button>
            <button
              className={cs(css.mint)}
              type="button"
              onClick={onMintArtwork}
            >
              <span>mint</span>
              {price && <DisplayTezos mutez={price} formatBig={false} />}
            </button>
          </div>
        </div>
      </div>

      <div ref={modalRef} className={cs(css.modal, { [css.show]: showModal })}>
        {loading || !finalUrl ? (
          <div className={css.qr_loader}>
            <Loader />
          </div>
        ) : (
          <QRCode className={css.qr} url={finalUrl} />
        )}
        <p>
          Scan this QR Code on your mobile device to mint your unique iteration.
        </p>
        <BaseButton inputSize="large" color="primary" onClick={onModalClose}>
          Back
        </BaseButton>
      </div>
    </>
  )
}
