import style from "./GentkPicker.module.scss"
import React, {
  Dispatch,
  memo,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
} from "react"
import { GenerativeToken } from "../../../Types/entities/GenerativeToken"
import { GenerativeIterationsFilters } from "./GenerativeIterationsFilters"
import { IObjktFeatureFilter } from "../../../Types/entities/Objkt"
import { ipfsGatewayUrl } from "../../../Utils/ipfs"
import cs from "classnames"

interface GentkPickerProps {
  className?: string
  selected?: GenerativeToken | null
  tokens: GenerativeToken[]
  onSelect: (selectedGentk: GenerativeToken) => void
  featuresFilters: IObjktFeatureFilter[]
  setFeaturesFilters: Dispatch<SetStateAction<IObjktFeatureFilter[]>>
}

const _GentkPicker = ({
  className,
  selected,
  tokens,
  onSelect,
  featuresFilters,
  setFeaturesFilters,
}: GentkPickerProps) => {
  const handleSelect = useCallback(
    (gentk: GenerativeToken) => () => {
      onSelect(gentk)
    },
    [onSelect]
  )

  // make sure a gentk is always selected
  const selectedGentk = useMemo(() => {
    if (selected) return selected
    return tokens.length > 0 ? tokens[0] : null
  }, [tokens, selected])
  useEffect(() => {
    if (tokens.length > 0 && selected === null) {
      onSelect(tokens[0])
    }
  }, [tokens, onSelect, selected])

  return (
    <div className={cs(className, style.container)}>
      <div className={style.gentks}>
        {tokens.map(token => {
          const thumbnailSrc = ipfsGatewayUrl(token.thumbnailUri)
          return (
            <div
              key={token.id}
              className={cs(style.gentk, {
                [style["gentk--selected"]]: token.id === selectedGentk?.id,
              })}
              onClick={handleSelect(token)}
            >
              <img src={thumbnailSrc} />
              <div className={style.gentk_title}>{token.name}</div>
            </div>
          )
        })}
      </div>

      {selectedGentk && (
        <GenerativeIterationsFilters
          className={style.filters}
          token={selectedGentk}
          featuresFilters={featuresFilters}
          setFeaturesFilters={setFeaturesFilters}
        />
      )}
    </div>
  )
}

export const GentkPicker = memo(_GentkPicker)
