import style from "./ControlFactory.module.scss"
import { ControlDefinitions } from "Definitions/Controls"
import { FunctionComponent, useMemo } from "react"
import { TControl, TSettingType } from "Types/Settings"
import { Button } from "../UI/Button"

type PropsChildren = {
  Control: TControl
}
type Props = {
  onClickAdd?: () => void
  onClickRemove?: () => void
  disableRemove?: boolean
  type: TSettingType
  children: FunctionComponent<PropsChildren>
}
export function ControlFactory({
  type,
  children,
  onClickAdd,
  onClickRemove,
  disableRemove,
}: Props) {
  const Control = useMemo(() => ControlDefinitions[type].input, [type])
  return (
    <div className={style.container}>
      {onClickRemove && (
        <Button
          disabled={disableRemove}
          className={style.button}
          onClick={onClickRemove}
        >
          <i aria-hidden="true" className="fa-solid fa-minus" />
        </Button>
      )}
      {children({
        Control,
      })}
      {onClickAdd && (
        <Button className={style.button} onClick={onClickAdd}>
          <i aria-hidden="true" className="fa-solid fa-plus" />
        </Button>
      )}
    </div>
  )
}
