import { useMemo } from "react"
import { FxParamInputChangeHandler } from "../Controller"
import css from "./Slider.module.scss"
import cs from "classnames"

interface Props {
  min?: number
  max?: number
  step?: number
  value: string
  onChange: FxParamInputChangeHandler
  className?: string
}
export function Slider({ value, onChange, min, max, step, className }: Props) {
  const pos = useMemo(() => {
    const mn = typeof min === "undefined" ? 0 : min
    const mx = typeof max === "undefined" ? 0 : max
    return (parseFloat(value) - mn) / (mx - mn)
  }, [value, min, max])
  return (
    <div className={cs(css.root, className)}>
      <input
        type="range"
        value={value}
        onChange={onChange}
        min={min}
        max={max}
        step={step}
      />
      <div className={cs(css.slider)}>
        <div className={cs(css.track)} style={{ width: `${pos * 100}%` }} />
        <div
          className={cs(css.thumb)}
          style={{ left: `calc(0.5vw + ${pos} * (100% - 1vw))` }}
        />
      </div>
    </div>
  )
}
