export function sum(arr: number[]): number {
  return arr.reduce((prev, acc) => acc + prev, 0)
}

export function getMutezDecimalsNb(x: number): number {
  const mu = Math.floor(Math.abs(x))
  const st = (mu / 1000000).toString()
  const split = st.split(".")
  return split.length > 1 ? split.pop()?.length || 0 : 0
}
