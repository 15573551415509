export interface SharedParamsMinterSettings {
  socket_connection: boolean
  mint_pass_group_address: string
  event_id: string
  event_password: string
  force_mode: string
}

export type TSharedParamsMinterEvent =
  | "params:definition:update"
  | "inputs:update"
  | "params:refresh-soft"
  | "params:definition:request"

export const addLiveMintingRouteToUrl = (
  url: string,
  route: string,
  params: any = {}
) => {
  const autonomyPrefix = "https://autonomy.io/apps/irl/"

  const baseUrl = url

  // Check if the URL starts with the prefix
  if (baseUrl.startsWith(autonomyPrefix)) {
    // If it does, remove the prefix and decode the rest of the URL
    url = decodeURIComponent(url.slice(autonomyPrefix.length))
  }

  // Parse the URL
  const parsedUrl = new URL(url)

  // Add the route to the pathname
  parsedUrl.pathname += route

  // Iterate through the parameters object and add each key-value pair to the searchParams
  for (const key in params) {
    parsedUrl.searchParams.append(key, params[key])
  }

  console.log("parsed:")
  console.log(parsedUrl.href)

  // If the original URL started with the prefix, add it back and re-encode the URL
  if (baseUrl.startsWith(autonomyPrefix)) {
    return autonomyPrefix + encodeURIComponent(parsedUrl.href)
  } else {
    // Otherwise, return the URL as is
    return parsedUrl.href
  }
}
