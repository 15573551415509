import style from "./UserBadge.module.scss"
import cs from "classnames"
import {
  getUserName,
  isDonator,
  isPlatformOwned,
  isUserVerified,
  userAliases,
} from "Utils/user"
import { Avatar } from "./Avatar"
import { IProps as IEntityBadgeProps } from "./EntityBadge"
import { useMemo } from "react"

export type Props = IEntityBadgeProps
export function UserBadge({
  user,
  prependText,
  size = "regular",
  hasLink = true,
  avatarSide = "left",
  displayAddress = false,
  displayAvatar = true,
  displayVerified = false,
  className,
}: Props) {
  // the user goes through an aliases check
  const userAlias = useMemo(() => user && userAliases(user), [user])
  const verified = user && isUserVerified(user)
  // alias can force no link
  hasLink = user && hasLink && !userAlias.preventLink

  return user ? (
    <div
      className={cs(
        {
          [style.container]: !hasLink,
          [style.default_font_styles]: true,
          [style.no_avatar]: !displayAvatar,
        },
        style.container,
        style[`side-${avatarSide}`],
        className
      )}
    >
      {displayAvatar && (
        <Avatar
          uri={userAlias.avatarUri}
          className={cs(style.avatar, style[`avatar-${size}`], {
            [style.avatar_mod]: isPlatformOwned(userAlias),
            [style.avatar_donation]: isDonator(userAlias),
          })}
        />
      )}

      <div className={cs(style.user_infos)}>
        <span className={cs(style.user_name)}>
          {prependText && (
            <span className={cs(style.prepend)}>{prependText}</span>
          )}
          <span
            className={cs({
              [style.moderator]: isPlatformOwned(userAlias),
              [style.donation]: isDonator(userAlias),
            })}
          >
            {getUserName(userAlias, 15)}
          </span>
          {verified && displayVerified && (
            <i
              aria-hidden
              className={cs("fas", "fa-badge-check", style.verified)}
            />
          )}
        </span>

        {displayAddress && (
          <span className={cs(style.user_address)}>{userAlias.id}</span>
        )}
      </div>
    </div>
  ) : (
    <></>
  )
}
