import style from "./LayoutFxLogo.module.scss"
import cs from "classnames"
import { PropsWithChildren } from "react"

interface Props {}
export function LayoutFxLogo({ children }: PropsWithChildren<Props>) {
  return (
    <div className={cs(style.root)}>
      {children}
      <div className={cs(style.logo)}>
        <span>powered by </span>
        <img src="/images/logo.svg" alt="fx logo" />
        <span> fxhash</span>
      </div>
    </div>
  )
}
