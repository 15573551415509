import { ipfsGatewayUrl } from "Utils/ipfs"
import { Objkt } from "Types/entities/Objkt"
import { IMintOperation } from "../Services/Indexing/ContractHandlers/IssuerHandler"
import { GenerativeToken } from "../Types/entities/GenerativeToken"
import { User } from "../Types/entities/User"

export function getObjktUrl(objkt: Objkt): string {
  return objkt.slug ? `/gentk/slug/${objkt.slug}` : `/gentk/${objkt.id}`
}

/**
 * Given an gentk which has an issuer, outputs a display URL for a live
 * version
 */
export function gentkLiveUrl(gentk: Objkt): string {
  return ipfsGatewayUrl(
    `${gentk.issuer.generativeUri}/?fxhash=${gentk.generationHash}`
  )
}

/**
 * Given an gentk which has an issuer, outputs a display URL for a live
 * version
 */
export function gentkUrl(
  ipfs: string,
  hash: string,
  minter: string,
  inputBytes?: string
) {
  let query = `?fxhash=${hash}`
  query += `&fxminter=${minter}`
  if (inputBytes) {
    query += `&fxparams=${inputBytes}`
  }
  return ipfsGatewayUrl(`${ipfs}/${query}`)
}

export function getObjtkFromMintAndGentk(
  mint: IMintOperation,
  gentk: GenerativeToken
): Objkt {
  const date = mint.timestamp.toDateString()
  return {
    id: gentk.id + 1000 + mint.iteration,
    version: 1,
    issuer: gentk,
    owner: {
      id: mint.minterAddress,
    } as User,
    assigned: true,
    generationHash: mint.hash,
    duplicate: false,
    iteration: mint.iteration,
    displayUri: "",
    thumbnailUri: "",
    tags: [],
    name: `${gentk.name} #${mint.iteration}`,
    features: [],
    rarity: 0.42, // to generate
    metadataUri: "",
    royalties: 100,
    royaltiesSplit: [],
    actions: [],
    createdAt: date,
    updatedAt: date,
    assignedAt: date,
  }
}
