import { ViewerModeDefinitions } from "Definitions/Settings"
import { EViewerMode, TSettings } from "Types/Settings"

const SETTING_SER_COMP_SEP = ","

/**
 * Serializes a settings object
 */
export function serializeSettings(settings: TSettings): string {
  const serializedComponents: string[] = []
  for (const set in settings) {
    const setting = settings[set]
    const serialized = setting.definition.multiple
      ? `${setting.definition.id}:[${setting.value
          .map((val: any) => encodeURIComponent(val))
          .join(";")}]`
      : `${setting.definition.id}:${encodeURIComponent(setting.value)}`
    serializedComponents.push(serialized)
  }
  return serializedComponents.join(SETTING_SER_COMP_SEP)
}

/**
 * Given some serialized settings and a viewer mode, outputs a Record<id, value>
 * for every setting of the mode. If none is found in the serialized object, the
 * default value will be used.
 */
export function deserializeSettings(
  serialized: string,
  mode: EViewerMode
): Record<string, any> {
  const settings: Record<string, any> = {}
  // components are split by _
  const components = serialized.split(SETTING_SER_COMP_SEP)
  for (const comp of components) {
    const [key, val] = comp.split(":")
    const matchArray = /\[([^[\]]*)]/g.exec(val)
    settings[key] = matchArray
      ? matchArray[1].split(";").map(val => decodeURIComponent(val))
      : decodeURIComponent(val)
  }
  const ret: Record<string, any> = {}
  const def = ViewerModeDefinitions[mode].settings
  for (const setting of def) {
    const value =
      typeof settings[setting.id] !== "undefined"
        ? settings[setting.id]
        : setting.default
    ret[setting.id] = value
  }
  return ret
}

/**
 * Given a mode and some settings, outputs the URL which will can be used to
 * point to corresponding page.
 */
export function buildUrl(mode: EViewerMode, settings: TSettings): string {
  const def = ViewerModeDefinitions[mode]
  return `/${def.route}/?settings=${serializeSettings(settings)}`
}

/**
 * Given an URL, outputs the mode and the settings
 */
export function parseUrlSettings(
  url: string,
  mode: EViewerMode
): Record<string, any> {
  const search = new URLSearchParams(url)
  return deserializeSettings(search.get("settings") || "", mode as EViewerMode)
}
