import style from "./LayoutGallery.module.scss"
import cs from "classnames"
import { CSSProperties, ReactNode } from "react"

interface IRootStyle extends CSSProperties {
  "--color-background": string
}

interface GalleryLayoutSettings {
  fading: string
  background: string
}

interface LayoutGalleryProps {
  leftColumn: ReactNode
  rightColumn: ReactNode
  settings: GalleryLayoutSettings
  className?: string
  classNameLeft?: string
  classNameRight?: string
}
export const LayoutGallery = ({
  leftColumn,
  rightColumn,
  settings,
  className,
  classNameLeft,
  classNameRight,
}: LayoutGalleryProps) => {
  return (
    <div
      className={cs(style.container, className)}
      style={
        {
          "--color-background": `#${settings.background}`,
        } as IRootStyle
      }
    >
      <div
        className={cs(
          style.leftColumn,
          {
            [style.fading]: settings.fading === "1",
          },
          classNameLeft
        )}
      >
        {leftColumn}
      </div>
      <div className={cs(style.rightColumn, classNameRight)}>{rightColumn}</div>
    </div>
  )
}
