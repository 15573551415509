// import { generateQRCode } from "lib/qrcode"
import { useEffect, useRef } from "react"
import useAsyncEffect from "use-async-effect"

interface Props {
  url: string
  className?: string
}
export function QRCode({ url, className }: Props) {
  const containerRef = useRef<HTMLDivElement>(null)

  useAsyncEffect(
    async isMounted => {
      const generateQRCode = (await import("Utils/qrcode")).generateQRCode
      const qr = generateQRCode(url)
      if (containerRef.current && isMounted()) {
        containerRef.current.innerHTML == ""
        qr.append(containerRef.current)
      }
    },
    () => {
      while (containerRef?.current?.firstChild) {
        containerRef.current.removeChild(containerRef.current.firstChild)
      }
    },
    [url]
  )

  return <div className={className} ref={containerRef} />
}
