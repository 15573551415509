import { createContext } from "react"

/**
 * The Settings context is instanciated by the ModeRootWrapper with the settings
 * loaded from the URL, if any.
 * It provides a global access to the settings to avoid props drilling.
 */

type TSettingsContext = Record<string, string>

const defaultContext: TSettingsContext = {}

export const SettingsContext = createContext(defaultContext)
