import cs from "classnames"
import { useEffect, useRef } from "react"
import QR from "qrcode"

interface Props {
  text: string
  size?: number
  className?: string
}
export function QRCode({ text, size = 300, className }: Props) {
  const cvs = useRef<HTMLCanvasElement>(null)

  useEffect(() => {
    if (cvs.current) {
      QR.toCanvas(cvs.current, text, {
        width: size,
        margin: 1,
      })
    }
  }, [text])

  return <canvas ref={cvs} className={cs(className)} />
}
