import style from "./Gallery.module.scss"
import { memo, useEffect, useMemo, useState } from "react"
import { IObjktFeatureFilter, Objkt } from "../../../Types/entities/Objkt"
import { GenerativeToken } from "../../../Types/entities/GenerativeToken"
import { Image } from "../../../Components/Image"
import { ShowcaseIteration } from "../../../Components/ShowcaseIteration/ShowcaseIteration"
import cs from "classnames"
import { filterGentks } from "Utils/features"
import { computeScoreFxhashNative } from "Utils/rarity"

interface GalleryProps {
  token: GenerativeToken
  featuresFilters: IObjktFeatureFilter[]
}

const _Gallery = ({ token, featuresFilters }: GalleryProps) => {
  const [selected, setSelected] = useState<Objkt>()

  // we filter the tokens using the feature filters here
  const filtered = useMemo(
    () => filterGentks(token.entireCollection!, featuresFilters),
    [token, featuresFilters]
  )

  // we compute the rarity score within the set
  const filteredWithRarity = useMemo(
    () => computeScoreFxhashNative(filtered),
    [filtered]
  )

  // sort by rarity
  const sorted = useMemo(
    () => filteredWithRarity.sort((a, b) => a.fxhashRarity - b.fxhashRarity),
    [filteredWithRarity]
  )

  useEffect(() => {
    setSelected(sorted[0])
  }, [sorted])

  const displayRight = selected || sorted[0]

  return (
    <div className={style.container}>
      {sorted?.length > 0 ? (
        <div className={cs(style.gallery)}>
          <div className={style.gallery_elements}>
            {sorted?.map(iteration => {
              return (
                <div
                  key={iteration.id}
                  className={cs(style.element, {
                    [style.selected]: selected?.id === iteration.id,
                  })}
                  onClick={() => setSelected(iteration)}
                >
                  <Image
                    mode="responsive"
                    ipfsUri={iteration.metadata?.displayUri}
                    image={iteration.captureMedia}
                    alt=""
                  />
                </div>
              )
            })}
          </div>
        </div>
      ) : (
        <div className={cs(style.gallery_empty)}>
          <p>There is no iteration matching the filters you selected</p>
        </div>
      )}

      {displayRight && (
        <div className={style.rarest}>
          <ShowcaseIteration
            token={token}
            label={displayRight === sorted[0] ? "Rarest:" : "Selected:"}
            objkt={displayRight}
            totalIterations={sorted.length}
            showRarity="description"
          />
        </div>
      )}
    </div>
  )
}

export const Gallery = memo(_Gallery)
