import { cloneDeep } from "@apollo/client/utilities"
import { User } from "Types/entities/User"
import { ContractIndexingHandler } from "./ContractHandler"

export type TApprovals = Record<string, boolean>

export interface IMintOperation {
  hash: string
  timestamp: Date
  iteration: number
  minterAddress: string
  sender?: User | false
  inputBytes?: string
}

interface IProject {
  mints: IMintOperation[]
}

export interface GentkContractState {
  projects: Record<string, IProject>
}

export const GentkContractHandler: ContractIndexingHandler<GentkContractState> =
  {
    init: () => {
      return {
        projects: {},
      }
    },

    handlers: {
      // collaborators can make any proposal
      mint: async (op, state) => {
        const {
          parameter: { value },
        } = op
        // the project ID
        const projectID: string = value.issuer_id
        // clone the state
        const nstate = cloneDeep(state)
        // if the project doesn't exist, we create it
        if (!nstate.projects[projectID]) {
          nstate.projects[projectID] = {
            mints: [],
          }
        }
        // get the iteration number
        const iteration = parseInt(value.iteration)
        // we add this mint operation to the arrat of mints
        nstate.projects[projectID].mints.push({
          hash: op.hash,
          timestamp: new Date(op.timestamp),
          iteration: iteration,
          minterAddress: value.address,
          inputBytes: value.input_bytes,
        })

        return nstate
      },
    },
  }
