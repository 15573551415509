import style from "./ArtworkFrame.module.scss"
import cs from "classnames"

interface Props {
  url: string
  onLoad?: () => void
  onError?: () => void
  className?: string
}
export function ArtworkFrame({ url, onLoad, onError, className }: Props) {
  return (
    <iframe
      title="artwork"
      src={url}
      className={cs(style.frame, className)}
      sandbox="allow-scripts allow-same-origin"
      onLoad={onLoad}
      onError={onError}
    />
  )
}
