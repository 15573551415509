import style from "./Carousel.module.scss"
import cs from "classnames"
import { Objkt } from "Types/entities/Objkt"
import { useMemo, useRef, useState } from "react"
import { useInterval } from "Hooks/useInterval"
import { gentkLiveUrl } from "Utils/objkt"
import { GentkImageOrFrame } from "Components/Artwork/ArtworkImageOrFrame"
import { GentkInfos } from "./GentkInfos"
import { GenerativeToken } from "Types/entities/GenerativeToken"

interface Props {
  token: GenerativeToken
  gentks: Objkt[]
  intervalMs: number
  loadIframes: boolean
}
export function RarityCyclerCarousel({
  token,
  gentks,
  intervalMs,
  loadIframes,
}: Props) {
  // the cursor pointing to the element displayed
  const [cursor, setCursor] = useState<number>(0)
  // reference to the carousel container
  const carouselRef = useRef<HTMLDivElement>(null)

  const selected = useMemo(() => gentks[cursor], [gentks, cursor])

  // every N seconds (delay), move the cursor by 1
  useInterval(
    () => {
      setCursor((cursor + 1) % gentks.length)
    },
    intervalMs,
    false,
    [cursor, gentks]
  )

  // compute the X offset based on the cursor -> used to shift the carousel
  const offset = useMemo(() => {
    return `calc(-${cursor}.5 * (var(--gutter) + var(--carousel-height)) + (0.5 * var(--gutter)))`
  }, [cursor])

  return (
    <div className={cs(style.root)}>
      <div className={cs(style.carousel_wrapper)}>
        <div
          ref={carouselRef}
          className={cs(style.carousel)}
          style={{
            transform: `translateX(${offset})`,
          }}
        >
          {gentks.map((gentk, idx) => {
            const active = idx === cursor

            return (
              <div
                key={gentk.id}
                className={cs(style.item, {
                  [style.active]: active,
                })}
              >
                <GentkImageOrFrame
                  gentk={gentk}
                  frame={loadIframes && active}
                />
              </div>
            )
          })}
        </div>
      </div>

      <GentkInfos token={token} gentk={selected} count={gentks.length} />
    </div>
  )
}
