import { useRTConnection } from "Hooks/useChannel"
import { GenerativeToken } from "Types/entities/GenerativeToken"
import { paramintChannelName } from "./shared"
import { useEffect, useMemo, useState } from "react"
import { LoaderBlock } from "Components/Layout/LoaderBlock"
import { ParmsMinterRootSettings } from "./Root"
import { ParamsMinterControls } from "Components/Viewers/ParamsMinter/Controls"
import { BroadcastChannelNetworkInterface } from "Utils/Network/BroadcastChannelNetworkInterface"
import { SocketNetworkInterface } from "Utils/Network/SocketNetworkInterface"
import { FxParamDefinition, FxParamType } from "Types/Params"
import { NoParamsStation } from "Components/Viewers/ParamsMinter/NoParamsStation"

interface IProps extends ParmsMinterRootSettings {
  token: GenerativeToken
}
export function ParamsMinterController({ token, ...settings }: IProps) {
  const networkInterface =
    (settings.socket_connection as any) === "1"
      ? SocketNetworkInterface
      : BroadcastChannelNetworkInterface

  const connection = useRTConnection(
    networkInterface,
    paramintChannelName(token),
    "parent"
  )

  const { broadcast, reactor, network } = connection

  const targetUrl = useMemo(
    () => `${location.pathname}${location.search}&context=target`,
    [location]
  )

  // params definition received from the piece. if the piece doesn't have params
  // value will be set to null. undefined means we haven't received data yet
  const [paramsDefinition, setParamsDefinition] = useState<
    FxParamDefinition<FxParamType>[] | null | undefined
  >([])

  // check if there is a target instance connected
  const hasTarget = network.some(node => node.role === "target")

  // when the piece is loaded, ParamsMinterControls will request for the params
  // so we can listen to the event to deduce if the project has params or not
  useEffect(() => {
    const listener = reactor.addEventListener(
      "params:definition:update",
      event => {
        setParamsDefinition(event.data.paramsDefinition)
      }
    )
    return () => {
      reactor.removeEventListener("params:definition:update", listener)
    }
  }, [reactor])

  if (!hasTarget) {
    return (
      <LoaderBlock height="100vh">
        <div>
          open{" "}
          <a href={targetUrl} target="_blank">
            {targetUrl.slice(0, 50) + "..."}
          </a>{" "}
          in a new window
        </div>
      </LoaderBlock>
    )
  }

  if (settings.force_mode === "params") {
    return paramsDefinition ? (
      <ParamsMinterControls
        token={token}
        paramsDefinition={paramsDefinition}
        connection={connection}
        {...settings}
      />
    ) : (
      <LoaderBlock height="100vh">
        waiting to receive settings from the project
      </LoaderBlock>
    )
  }

  if (settings.force_mode === "classic") {
    return (
      <NoParamsStation token={token} connection={connection} {...settings} />
    )
  }

  // settings.force_mode === "auto"
  return typeof paramsDefinition === "undefined" ? (
    <LoaderBlock height="100vh">
      waiting to receive settings from the project
    </LoaderBlock>
  ) : paramsDefinition === null ? (
    <NoParamsStation token={token} connection={connection} {...settings} />
  ) : (
    <ParamsMinterControls
      token={token}
      paramsDefinition={paramsDefinition}
      connection={connection}
      {...settings}
    />
  )
}
