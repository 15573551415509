import style from "./LiveRevealRoot.module.scss"
import cs from "classnames"
import { TModeRoot } from "Types/Settings"
import { useFetchProjects } from "Hooks/useFetchProjects"
import { useIndexer } from "Hooks/useIndexer"
import {
  GentkContractHandler,
  GentkContractState,
} from "Services/Indexing/ContractHandlers/GentkHandler"
import { ProjectLiveReveal } from "Components/Viewers/ProjectLiveReveal/ProjectLiveReveal"
import { enhanceIssuerStateWithAuthors } from "Utils/transforms"
import { GenerativeToken } from "Types/entities/GenerativeToken.js"
import { IProjectIteration } from "Types/Project"

interface ISettings {
  projects_id: string[]
  delay: string
  display_minter: string
  bg_color: string
  dual_view: string
}

interface UseIndexTokenOptions {
  displayMinter: boolean
}

const useIndexToken = (
  projectIds: string[],
  { displayMinter }: UseIndexTokenOptions
): [
  GenerativeToken | false,
  GentkContractState | null,
  IProjectIteration[] | null,
] => {
  // fetches the overall project on fxhash API
  const tokens = useFetchProjects(projectIds.map(id => parseInt(id)))
  // make sure no undefined goes through
  const tokensWithoutUndefined = tokens.filter(val => !!val)

  // index whenever we get the data from the backend
  const { data } = useIndexer<GentkContractState>(
    tokensWithoutUndefined.length > 0,
    {
      address: process.env.REACT_APP_ADDRESS_GENTK_V3,
      queryParams:
        tokensWithoutUndefined.length > 1
          ? {
              "parameter.issuer_id.in": tokensWithoutUndefined
                .map(tok => tok.id)
                .join(","),
            }
          : {
              "parameter.issuer_id.eq": tokensWithoutUndefined[0]?.id,
            },
    },
    GentkContractHandler,
    10000,
    displayMinter ? enhanceIssuerStateWithAuthors : undefined
  )

  console.log(tokensWithoutUndefined)
  // safely extract the first token from the response
  const token = tokensWithoutUndefined.length > 0 && tokensWithoutUndefined[0]
  const iterations = tokensWithoutUndefined.reduce((acc, token) => {
    const tokenIterations =
      data?.projects[token?.id]?.mints.map(mint => ({
        iteration: mint.iteration,
        hash: mint.hash,
        mintedAt: mint.timestamp,
        viewed: false,
        owner: mint.sender || null,
        inputBytes: mint.inputBytes,
        minterAddress: mint.minterAddress,
        token,
      })) || []

    return [...acc, ...tokenIterations]
  }, [] as IProjectIteration[])
  return [
    token,
    data,
    iterations.length > 0
      ? // annoying ts error :scroned
        // @ts-ignore
        iterations.sort((a, b) => a.mintedAt - b.mintedAt)
      : null,
  ]
}

function ReavealFeed(props: { settings: ISettings; projectIds: string[] }) {
  const { settings, projectIds } = props
  const [token, data, iterations] = useIndexToken(projectIds, {
    displayMinter: settings.display_minter === "1",
  })
  const dualRevealToken = settings.dual_view === "1"

  return token ? (
    <ProjectLiveReveal
      delayMs={parseInt(settings.delay)}
      tokenIterations={iterations}
      hasKeyboardControls
      dual={dualRevealToken}
    />
  ) : (
    <div>Gentk not found</div>
  )
}

export const LiveRevealRoot: TModeRoot<ISettings> = ({ settings }) => {
  console.log(settings.dual_view)
  return (
    <div
      className={cs(style.root)}
      style={{ backgroundColor: settings.bg_color }}
    >
      <div className={cs(style.project_container, style.dual)}>
        <ReavealFeed projectIds={settings.projects_id} settings={settings} />
      </div>
    </div>
  )
}
