import { PropsWithChildren } from "react"
import style from "./FastLinksRoot.module.scss"
import cs from "classnames"

interface Props {
  title: string
}
function QuickGroup({ title, children }: PropsWithChildren<Props>) {
  return (
    <div>
      <h1>{title}</h1>
      <div>{children}</div>
    </div>
  )
}

export function FastLinksRoot() {
  return (
    <div className={cs(style.root)}>
      <QuickGroup title="NFTSE 2023">
        <a href="http://live.fxhash.xyz/params-minter/?settings=project_id:28045,socket_connection:1,force_mode:classic,mint_pass_group_address:KT1KPevRdXE43hkNcgq9PVNjvyh7w7z97bvz,event_id:nft-show-europe-or-p1xelfool,event_password:brightwolf39%2B">
          pixelfool: ipad
        </a>
        <a href="http://live.fxhash.xyz/params-minter/?settings=project_id:28045,socket_connection:1,force_mode:classic,mint_pass_group_address:KT1KPevRdXE43hkNcgq9PVNjvyh7w7z97bvz,event_id:nft-show-europe-or-p1xelfool,event_password:brightwolf39%2B&context=target">
          pixelfool: screen
        </a>
        <a href="http://live.fxhash.xyz/params-minter/?settings=project_id:28068,socket_connection:1,force_mode:params,mint_pass_group_address:KT1TJk6NqVC7bu51Wx7sovBzvQSrymzFPheX,event_id:nft-show-europe-or-eliza-sj,event_password:loudtiger24-">
          elisa: ipad
        </a>
        <a href="http://live.fxhash.xyz/params-minter/?settings=project_id:28068,socket_connection:1,force_mode:params,mint_pass_group_address:KT1TJk6NqVC7bu51Wx7sovBzvQSrymzFPheX,event_id:nft-show-europe-or-eliza-sj,event_password:loudtiger24-&context=target">
          elisa: screen
        </a>
        <a href="http://live.fxhash.xyz/params-minter/?settings=project_id:28063,socket_connection:1,force_mode:params,mint_pass_group_address:KT1LMpBxiCHQ7MGqY5pNgUCmBruWz9fVzaee,event_id:nft-show-europe-or-hal09999,event_password:brightbear40!">
          hal: ipad
        </a>
        <a href="http://live.fxhash.xyz/params-minter/?settings=project_id:28063,socket_connection:1,force_mode:params,mint_pass_group_address:KT1LMpBxiCHQ7MGqY5pNgUCmBruWz9fVzaee,event_id:nft-show-europe-or-hal09999,event_password:brightbear40!&context=target">
          hal: screen
        </a>
        <a href="http://live.fxhash.xyz/live-minting-gallery?settings=project_id:28060,mint_pass_group_address:KT1BriszioGN3nfHFuGAy2PdokVjRWTigc55,event_id:nft-show-europe-or-piter-pasma,event_password:NFTSE2023,screen_time:60,scroll_speed_variation:10,nb_cols:3,fading:false,background:1d2227,num_chars_trunc:0">
          piter: gallery
        </a>
      </QuickGroup>
    </div>
  )
}
