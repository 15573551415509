import {
  useState,
  useEffect,
  useRef,
  LegacyRef,
  MutableRefObject,
  RefObject,
  ChangeEvent,
  useMemo,
} from "react"
import { hexToRgba, rgbaToHex } from "../utils"
import {
  FxParamControllerProps,
  Controller,
  BaseParamsInput,
} from "./Controller"
import classes from "./Color.module.scss"
import { RgbaColor, RgbaColorPicker } from "react-colorful"
import cx from "classnames"
import { BaseButton } from "../BaseInput"

interface LargeLabelProps {
  label: string
  value: any
}

function LargeLabel(props: LargeLabelProps) {
  const { label, value } = props
  return (
    <>
      <label className={classes.largeLabelLabel}>{label}</label>
      <input className={classes.largeLabelValue} value={value} disabled />
    </>
  )
}

export function ColorController(props: FxParamControllerProps<"color">) {
  const ref = useRef<HTMLDivElement>(null)
  const { label, id, onChange, value, layout = "box", size = "normal" } = props
  const [showPicker, setShowPicker] = useState(false)
  const handleToggleShowPicker = () => {
    setShowPicker(show => !show)
  }
  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.value)
  }
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (ref.current && !ref.current?.contains(event.target as Node)) {
        setShowPicker(false)
      }
    }
    window.addEventListener("mousedown", handleClickOutside)
    return () => {
      window.removeEventListener("mousedown", handleClickOutside)
    }
  }, [ref])
  const handleChangeColor = (newColor: RgbaColor) => {
    onChange(rgbaToHex(newColor.r, newColor.g, newColor.b, newColor.a))
  }
  const color = useMemo(() => hexToRgba(value), [value])
  const isLargeInput = size === "large"

  return (
    <Controller
      id={id}
      label={label}
      layout={layout}
      inputContainerProps={{ ref }}
      size={size}
    >
      <div className={classes.root}>
        <div className={cx(classes.rootContent, classes.pickerWrapper)}>
          {isLargeInput && (
            <>
              <RgbaColorPicker
                color={color}
                onChange={handleChangeColor}
                className={cx(classes.colorful, classes[`size-${size}`])}
              />
              <div className={classes.largeLabels}>
                <LargeLabel label="HEX" value={value} />
                <div className={classes.rgb}>
                  <div>
                    <LargeLabel label="RED" value={color.r} />
                  </div>
                  <div>
                    <LargeLabel label="GREEN" value={color.g} />
                  </div>
                  <div>
                    <LargeLabel label="BLUE" value={color.b} />
                  </div>
                </div>
              </div>
            </>
          )}
          {!isLargeInput && (
            <>
              <BaseButton
                className={cx(classes.squaredButton, classes[`size-${size}`], {
                  [classes.active]: showPicker,
                })}
                inputSize={size}
                onClick={handleToggleShowPicker}
              >
                <div
                  className={cx(classes.square, classes.leftTop)}
                  style={{
                    background: `linear-gradient(-45deg, ${value} 0%, ${value} 50%, ${value.slice(
                      0,
                      7
                    )} 50%, ${value.slice(0, 7)} 100%)`,
                  }}
                />
              </BaseButton>
              <BaseParamsInput
                type="text"
                id={`text-${id}`}
                onChange={handleInputChange}
                value={value}
                autoComplete="off"
                maxLength={9}
                minLength={2}
                inputSize={size}
              />
              {showPicker && (
                <div className={classes.pickerAbsoluteWrapper}>
                  <div className={classes.picker}>
                    <RgbaColorPicker
                      color={color}
                      onChange={handleChangeColor}
                      className={classes.colorful}
                    />
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </Controller>
  )
}
