import style from "./SettingsRoot.module.scss"
import cs from "classnames"
import { LayoutFxLogo } from "Containers/Layout/LayoutFxLogo"
import { FormEvent, useCallback, useEffect, useState } from "react"
import { EViewerMode, ISetting, TSettings } from "Types/Settings"
import { ModeSelector } from "./ModeSelector"
import { SettingsGroup } from "Components/Settings/SettingsGroup"
import { ViewerModeDefinitions } from "Definitions/Settings"
import { Button } from "Components/UI/Button"
import { buildUrl } from "Utils/Settings"
import { ControlFactory } from "Components/Controls/ControlFactory"
import { Link } from "react-router-dom"

export function SettingsRoot() {
  const [mode, setMode] = useState<EViewerMode | null>(null)
  const [settings, setSettings] = useState<TSettings | null>(null)

  useEffect(() => {
    // if (!mode) {
    //   setSettings(null)
    // }
    // if we have a mode, we want to generate a new settings object
    if (mode) {
      const def = ViewerModeDefinitions[mode].settings
      const n = {} as TSettings
      for (const set of def) {
        n[set.id] = {
          value: set.default,
          definition: set,
        } as ISetting
      }
      setSettings(n)
    }
  }, [mode])

  const updateSetting = useCallback((id: string, value: any) => {
    setSettings(oldSettings => {
      const curr = oldSettings![id]
      return {
        ...oldSettings,
        [id]: {
          value: value,
          definition: curr.definition,
        } as ISetting,
      }
    })
  }, [])

  const removeValueArray = useCallback(
    (id: string, settingValueArray: any[], idx: number) => () => {
      const newValue = [...settingValueArray]
      newValue.splice(idx, 1)
      updateSetting(id, newValue)
    },
    [updateSetting]
  )
  const addValueArray = useCallback(
    (id: string, settingValueArray: any[], idx: number) => () => {
      const newValue = [...settingValueArray]
      newValue.splice(idx, 0, "")
      updateSetting(id, newValue)
    },
    [updateSetting]
  )
  const updateValueArray = useCallback(
    (id: string, settingValueArray: any[], idx: number) => (val: any) => {
      const newValue = [...settingValueArray]
      newValue[idx] = val
      updateSetting(id, newValue)
    },
    [updateSetting]
  )

  const showSettings = !!(mode && settings)

  const onSubmit = useCallback(
    (event: FormEvent) => {
      event.preventDefault()
      if (!mode || !settings) return

      const url = `${window.location.origin}${buildUrl(mode, settings)}`
      window.location.href = url
    },
    [mode, settings]
  )

  return (
    <LayoutFxLogo>
      <Link to="/fast-links" className={style.fastLinks}>
        quick links
      </Link>
      <div className={cs(style.root)}>
        <h1>Settings</h1>
        <ModeSelector value={mode} onChange={setMode} />
        <form
          className={cs(style.mode_settings, {
            [style.show]: !!mode,
          })}
          onSubmit={onSubmit}
        >
          {showSettings && (
            <>
              {Object.keys(settings).map(id => {
                const setting = settings[id]
                const settingValue = setting.definition.multiple
                  ? setting.value || [""]
                  : setting.value
                return (
                  <SettingsGroup
                    key={id}
                    title={setting.definition.name}
                    infos={setting.definition.description}
                  >
                    {setting.definition.multiple ? (
                      settingValue.map((value: any, idx: number) => {
                        return (
                          <ControlFactory
                            disableRemove={settingValue.length <= 1}
                            onClickRemove={removeValueArray(
                              id,
                              settingValue,
                              idx
                            )}
                            onClickAdd={addValueArray(
                              id,
                              settingValue,
                              idx + 1
                            )}
                            type={setting.definition.type}
                          >
                            {({ Control }) => (
                              <Control
                                value={value}
                                onChange={updateValueArray(
                                  id,
                                  settingValue,
                                  idx
                                )}
                              />
                            )}
                          </ControlFactory>
                        )
                      })
                    ) : (
                      <ControlFactory type={setting.definition.type}>
                        {({ Control }) => (
                          <Control
                            value={setting.value}
                            onChange={val => updateSetting(id, val)}
                          />
                        )}
                      </ControlFactory>
                    )}
                  </SettingsGroup>
                )
              })}

              <div className={cs(style.submit)}>
                <Button type="submit">apply</Button>
              </div>
            </>
          )}
        </form>
      </div>
    </LayoutFxLogo>
  )
}
