import { useRTConnection } from "Hooks/useChannel"
import { GenerativeToken } from "Types/entities/GenerativeToken"
import { TParamulmintEvents, paramulmintChannelName } from "./shared"
import { ErrorMain } from "Components/UI/ErrorMain"
import { useEffect, useState } from "react"
import { Waiting } from "Components/UI/Waiting"
import { ParamsMinterViewer } from "Components/Viewers/ParamsMinter/Viewer"
import { ParamsMultiMinterSettings } from "./Root"
import { BroadcastChannelNetworkInterface } from "Utils/Network/BroadcastChannelNetworkInterface"
import { SocketNetworkInterface } from "Utils/Network/SocketNetworkInterface"

interface Props {
  tokens: GenerativeToken[]
  settings: ParamsMultiMinterSettings
}
export function ParamsMultiMinterTarget({ tokens, settings }: Props) {
  const networkInterface =
    (settings.socket_connection as any) === "1"
      ? SocketNetworkInterface
      : BroadcastChannelNetworkInterface
  const connection = useRTConnection<TParamulmintEvents>(
    networkInterface,
    paramulmintChannelName(tokens),
    "target"
  )
  const { broadcast, network, reactor } = connection

  const [selected, setSelected] = useState<GenerativeToken | null>(null)

  useEffect(() => {
    const listener = reactor.addEventListener("project:select", evt => {
      setSelected(evt.data)
    })

    return () => {
      reactor.removeEventListener("project:select", listener)
    }
  }, [])

  // count the number of parent nodes attached to this node
  const parents = network.filter(node => node.role === "parent")
  const nbParents = parents.length

  return nbParents === 1 ? (
    selected ? (
      <ParamsMinterViewer connection={connection} token={selected} />
    ) : (
      <Waiting title="Select a project to explore on the control station" />
    )
  ) : (
    <ErrorMain
      title={
        nbParents === 0
          ? "The controller isn't connected"
          : "There is more than 1 controller running"
      }
    >
      Please make sure to run one and only one controller window on this same
      machine.
    </ErrorMain>
  )
}
