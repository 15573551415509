import style from "./Controls.module.scss"
import { IUseChannelConnectionReturn } from "Hooks/useChannel"
import { GenerativeToken } from "Types/entities/GenerativeToken"
import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { LoaderBlock } from "Components/Layout/LoaderBlock"
import { useLocation } from "react-router-dom"
import {
  Controls,
  ControlsOnChangeDataHandler,
} from "Components/FxParams/Controls"
import {
  FxParamDefinition,
  FxParamType,
  FxParamTypeMap,
  FxParamsData,
} from "Components/FxParams/types"
import { BaseButton } from "Components/FxParams/BaseInput"
import { serializeParams } from "Components/FxParams/utils"
import { Image } from "Components/Image/index"
import { QRCode } from "Components/QRCode/QRCode"
import cx from "classnames"
import { useFetchLiveMintingUrl } from "Hooks/useFetchLiveMintingUrl"
import { Loader } from "Components/Utils/Loader"
import debounce from "lodash.debounce"
import { useClickOutside } from "Hooks/useClickOutside"
import {
  SharedParamsMinterSettings,
  TSharedParamsMinterEvent,
  addLiveMintingRouteToUrl,
} from "./shared"
import nl2br from "react-nl2br"
import { getAuthorNames } from "Utils/user"
import { DisplayTezos } from "Components/Display/DisplayTezos"

const getRandomHash = (l = 49) => {
  const alphabet = "123456789abcdefghijkmnopqrstuvwxyzABCDEFGHJKLMNPQRSTUVWXYZ"
  return (
    "oo" +
    Array(l)
      .fill(0)
      .map(_ => alphabet[(Math.random() * alphabet.length) | 0])
      .join("")
  )
}

interface IProps<Events extends string> extends SharedParamsMinterSettings {
  token: GenerativeToken
  paramsDefinition: FxParamDefinition<FxParamType>[]
  connection: IUseChannelConnectionReturn<Events | TSharedParamsMinterEvent>
  onGoBack?: () => void
}
export function ParamsMinterControls<Events extends string>({
  token,
  paramsDefinition,
  connection,
  onGoBack,
  ...settings
}: IProps<Events>) {
  const modalRef = useRef<HTMLDivElement>(null)
  const location = useLocation()
  const [showModal, setShowModal] = useState<boolean>(false)
  const [hash, _setHash] = useState<string>(
    token.metadata.previewHash || getRandomHash(49)
  )

  const { refetch, loading } = useFetchLiveMintingUrl(settings)
  const [finalUrl, setFinalUrl] = useState<string>()

  const [data, setData] = useState<FxParamsData>({})

  const { broadcast, reactor } = connection

  useEffect(() => {
    const listener = reactor.addEventListener(
      "params:definition:update",
      event => setData({})
    )
    return () => {
      reactor.removeEventListener("params:definition:update", listener)
    }
  }, [reactor])

  const targetUrl = useMemo(
    () => `${location.pathname}${location.search}&context=target`,
    [location]
  )

  const handleRefresh = useCallback(
    ({
      data,
      paramsDefinition,
      hash,
      minter,
    }: {
      data: FxParamsData
      paramsDefinition: FxParamDefinition<FxParamType>[]
      hash: string
      minter: string
    }) => {
      const inputBytes = serializeParams(data, paramsDefinition!)
      broadcast("inputs:update", {
        hash,
        minter,
        inputBytes,
      })
    },
    [broadcast]
  )

  const debouncedHandleRefresh = useCallback(
    debounce(handleRefresh, 200), // Adjust the debounce delay as per your needs
    []
  )

  const setHash = (hash: string) => {
    _setHash(hash)
    handleRefresh({ minter, hash, data, paramsDefinition })
  }
  const [minter, _setMinter] = useState<string>(
    token.metadata.previewMinter || getRandomHash(33)
  )

  const setMinter = (minter: string) => {
    _setMinter(minter)
    handleRefresh({ minter, hash, data, paramsDefinition })
  }

  const refreshExampleSeed = () => {
    _setHash(getRandomHash(49))
    _setMinter(getRandomHash(33))
    handleRefresh({ minter, hash, data, paramsDefinition })
  }

  const softRefresh = (changedParam: {
    id: string
    value: FxParamTypeMap[FxParamType]
  }) => {
    broadcast("params:refresh-soft", changedParam)
  }

  const handleChangeData: ControlsOnChangeDataHandler = (
    data,
    changedParam
  ) => {
    setData(data)
    const realtimeSync =
      paramsDefinition?.find(d => d.id === changedParam?.id)?.update === "sync"
    if (realtimeSync && changedParam) {
      softRefresh(changedParam)
    } else {
      debouncedHandleRefresh({ minter, hash, data, paramsDefinition })
    }
  }

  const toggleModal = () => setShowModal(!showModal)

  const onMintArtwork = async () => {
    toggleModal()

    const inputBytes = serializeParams(data, paramsDefinition!)
    const route = `/generative/${token.id}/explore-params`
    const queryParams = {
      fxhash: hash,
      fxparams: inputBytes,
    }

    const url = await refetch()
    const finalUrl = addLiveMintingRouteToUrl(url, route, queryParams)
    setFinalUrl(finalUrl)
  }

  const onModalClose = () => {
    toggleModal()
    setFinalUrl(undefined)
  }

  useClickOutside(modalRef, () => {
    if (showModal) onModalClose()
  })

  const price = token.pricingFixed?.price

  return paramsDefinition ? (
    <>
      <div className={cx(style.controllerWrapper, { [style.blur]: showModal })}>
        <div className={style.controllContainer}>
          <div className={style.controls}>
            {paramsDefinition && (
              <Controls
                params={paramsDefinition}
                data={data}
                onChangeData={handleChangeData}
                size="large"
              />
            )}
          </div>
          <div className={style.bottomControls}>
            <div className={cx(style.buttonRow)}>
              {/*
              <HashButton
                title="Example seed"
                hash={hash}
                onChangeHash={setHash}
                createRandomHash={() => getRandomHash(49)}
              />
              <HashButton
                title="Example minter address"
                hash={minter}
                onChangeHash={setMinter}
                createRandomHash={() => getRandomHash(33)}
              />
  */}
            </div>
            <div className={style.buttonRow}>
              {/*
              <BaseButton
                className={style.refreshButton}
                inputSize="large"
                onClick={handleRefresh}
              >
                Reload artwork
              </BaseButton>
*/}
              <BaseButton
                className={style.refreshButton}
                onClick={refreshExampleSeed}
                inputSize="large"
                color="primary"
              >
                refresh example seed
              </BaseButton>
              <BaseButton
                className={style.mintButton}
                inputSize="large"
                color="primary-inverted"
                onClick={onMintArtwork}
              >
                mint artwork
                {price && (
                  <>
                    &nbsp;
                    <DisplayTezos mutez={price} formatBig={false} />
                  </>
                )}
              </BaseButton>
            </div>
          </div>
        </div>
        <div className={style.projectSidebar}>
          <div className={style.projectInfo}>
            <div className={style.previewImage}>
              <Image
                alt=""
                image={token?.captureMedia}
                ipfsUri={token.metadata?.thumbnailUri}
              />
            </div>
            <h2>{token.name}</h2>
            <div className={style.artists}>{getAuthorNames(token.author)}</div>
            <p>{nl2br(token.metadata.description)}</p>
            {token.metadata?.description && (
              <div className={style.mintingInstructions}>
                <h3>Minting instructions</h3>
                <p>
                  <>{nl2br(token.metadata.description)}</>
                </p>
              </div>
            )}
          </div>
          {onGoBack && (
            <BaseButton
              className={style.backButton}
              inputSize="large"
              color="primary"
              onClick={onGoBack}
            >
              Back to gallery
            </BaseButton>
          )}
        </div>
      </div>
      <div
        ref={modalRef}
        className={cx(style.modal, { [style.show]: showModal })}
      >
        {loading || !finalUrl ? (
          <div className={style.qr_loader}>
            <Loader />
          </div>
        ) : (
          <QRCode className={style.qr} url={finalUrl} />
        )}
        <p>
          Ready to mint a unique artwork with your favorite parameters? Let's do
          one quick check before proceeding!
        </p>
        <p>
          Refresh the example seed a few times and notice the artwork: if it
          changes, a final variation will occur after minting. If it stays the
          same, your minted piece will be identical to the preview.
        </p>
        <p>
          With this observation in mind, go ahead and mint your unique artwork
          by scanning the QR code above.{" "}
        </p>
        <BaseButton
          onClick={refreshExampleSeed}
          inputSize="large"
          color="primary-inverted"
        >
          Refresh example seed
        </BaseButton>
        <BaseButton inputSize="large" color="primary" onClick={onModalClose}>
          Back to configuration
        </BaseButton>
      </div>
    </>
  ) : (
    <LoaderBlock height="100vh">
      <div>
        open{" "}
        <a href={targetUrl} target="_blank">
          {targetUrl.slice(0, 50) + "..."}
        </a>{" "}
        in a new window
      </div>
    </LoaderBlock>
  )
}
