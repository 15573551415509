import {
  forwardRef,
  useEffect,
  useState,
  useRef,
  useImperativeHandle,
} from "react"
import style from "./ArtworkFrame.module.scss"
import cs from "classnames"

interface Props {
  url?: string
  textWaiting?: string
  onLoaded?: () => void
  hasLoading?: boolean
}

export interface ArtworkIframeRef {
  reloadIframe: () => void
  getHtmlIframe: () => HTMLIFrameElement | null
}

export const ArtworkIframeWithFrame = forwardRef<ArtworkIframeRef, Props>(
  ({ url, textWaiting, onLoaded, hasLoading = true }, ref) => {
    const [loading, setLoading] = useState<boolean>(false)
    const isLoaded = useRef<boolean>(false)
    const iframeRef = useRef<HTMLIFrameElement>(null)

    useEffect(() => {
      setLoading(!isLoaded.current)
    }, [])

    const reloadIframe = () => {
      if (url && iframeRef?.current?.contentWindow) {
        setLoading(true)
        iframeRef.current.contentWindow.location.replace(url)
      }
    }

    useEffect(() => {
      // when the url changes, we set reload to true
      setLoading(true)
      if (url && iframeRef?.current?.contentWindow) {
        // keep iframe history hidden
        iframeRef.current.contentWindow.location.replace(url)
      }
    }, [url, iframeRef])

    // set iframe state to loaded and set ref to loaded to prevent loader init to loading
    const setIframeLoaded = () => {
      isLoaded.current = true
      setLoading(false)
    }

    const getHtmlIframe = (): HTMLIFrameElement | null => {
      return iframeRef.current
    }

    useImperativeHandle(ref, () => ({
      reloadIframe,
      getHtmlIframe,
    }))

    return (
      <iframe
        ref={iframeRef}
        sandbox="allow-scripts allow-same-origin"
        className={cs(style.frame)}
        onLoad={() => {
          onLoaded?.()
          setIframeLoaded()
        }}
        allow="accelerometer; camera; gyroscope; microphone; xr-spatial-tracking;"
      />
    )
  }
)
