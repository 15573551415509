import style from "./LiveRevealView.module.scss"
import utils from "Styles/Utils.module.scss"
import { IProjectIteration } from "Types/Project"
import cs from "classnames"
import { RevealIframe } from "Components/Reveal/RevealIframe"
import { useContext, useEffect, useMemo, useRef } from "react"
import { getLiveUrl } from "Utils/fxhash"
import { UserBadge } from "Components/User/UserBadge"
import { LoaderBlock } from "Components/Layout/LoaderBlock"
import { SettingsContext } from "Context/SettingsContext"

interface Props {
  iteration?: IProjectIteration
}

export function LiveRevealView({ iteration }: Props) {
  const settings = useContext(SettingsContext)
  const activeIteration = iteration

  const liveUrl = useMemo(
    () =>
      activeIteration &&
      getLiveUrl(activeIteration.token?.generativeUri || "", {
        hash: activeIteration.hash,
        queryParams: settings.display_query_params,
        inputBytes: activeIteration.inputBytes,
        minterAddress: activeIteration.minterAddress,
      }),
    [activeIteration, settings.display_query_params]
  )

  // should we display textual informations underneath ?
  const displayInfos =
    settings.display_title === "1" || settings.display_minter === "1"

  const displayMinimal = settings.display_minimal === "1"

  const iframeContainerStyle = {
    0: style.reveal_iframe_container_square,
    1: style.reveal_iframe_container_9_16,
    2: style.reveal_iframe_container_16_9,
  }[settings.display_aspect_ratio]

  const dualView = settings.dual_view !== ""

  const fadeAnimation = settings.rotate_animation === "0"

  return (
    <div className={cs(style.root, { [style.dual]: dualView })}>
      {activeIteration ? (
        <>
          <div className={cs(style.frame_wrapper)}>
            <RevealIframe
              className={style.reveal_container}
              classNameIframeContainer={iframeContainerStyle}
              url={liveUrl}
              resetOnUrlChange={true}
              animation={fadeAnimation ? "fade" : "rotate"}
            />
          </div>
          <div
            className={cs(style.reveal_infos, {
              [utils.hidden]: !displayInfos,
              [style.minimal]: displayMinimal,
            })}
          >
            {settings.display_title === "1" && (
              <h3>
                {activeIteration.token?.name} #{activeIteration.iteration}
              </h3>
            )}
            {settings.display_minter === "1" && activeIteration.owner && (
              <UserBadge
                prependText="minted by"
                user={activeIteration.owner}
                size="small"
                avatarSide="right"
                className={cs(style.infos__user_badge)}
              />
            )}
          </div>
        </>
      ) : (
        <LoaderBlock height="100vh" size="regular">
          <div className={style.scroned_container}>waiting for mint</div>
        </LoaderBlock>
      )}
    </div>
  )
}
