import style from "./GenerativeIterationsFilters.module.scss"
import cs from "classnames"
import { Dispatch, SetStateAction, useMemo } from "react"
import {
  GenerativeToken,
  GenerativeTokenFeature,
} from "../../../Types/entities/GenerativeToken"
import {
  IObjktFeatureFilter,
  objktFeatureType,
} from "../../../Types/entities/Objkt"
import { useQuery } from "@apollo/client"
import { Qu_genTokenFeatures } from "../../../Queries/Projects"
import { LoaderBlock } from "../../../Components/Layout/LoaderBlock"
import {
  InputMultiList,
  MultiListItem,
} from "../../../Components/Input/InputMultiList"

interface IFeatureListItems {
  name: string
  listItems: MultiListItem[]
}

interface Props {
  className: string
  token: GenerativeToken
  featuresFilters: IObjktFeatureFilter[]
  setFeaturesFilters: Dispatch<SetStateAction<IObjktFeatureFilter[]>>
}
export function GenerativeIterationsFilters({
  className,
  token,
  featuresFilters,
  setFeaturesFilters,
}: Props) {
  const features = token.features!

  // process the features for easier display
  const processedFeatures = useMemo<IFeatureListItems[] | null>(() => {
    if (!features) return null

    // sort the traits by number of occurences
    const processed: IFeatureListItems[] = features.map(feature => ({
      name: feature.name,
      listItems: [...feature.values]
        .sort((a, b) => ("" + b.value < "" + a.value ? -1 : 1))
        .map(value => ({
          value: value.value,
          props: {
            name: value.value,
            occur: value.occur,
          },
        })),
    }))
    return processed
  }, [features])

  const updateFeatureFilter = (name: string, values: any[]) => {
    const newFilters = featuresFilters.filter(filter => filter.name !== name)
    if (values.length > 0) {
      newFilters.push({
        name: name,
        values,
        type: objktFeatureType(values[0]),
      })
    }
    setFeaturesFilters(newFilters)
  }

  const filtersNb = useMemo(
    () => featuresFilters.reduce((prev, acc) => prev + acc.values.length, 0),
    [featuresFilters]
  )

  return (
    <>
      <div className={cs(style.container, className)}>
        {processedFeatures &&
          processedFeatures.length > 0 &&
          processedFeatures?.map(feature => (
            <div key={feature.name} className={style.feature_category}>
              <div className={style.feature_category_title}>{feature.name}</div>
              <InputMultiList
                listItems={feature.listItems}
                selected={
                  featuresFilters.find(filter => filter.name === feature.name)
                    ?.values || []
                }
                onChangeSelected={values =>
                  updateFeatureFilter(feature.name, values)
                }
                className={cs(style.multi_list)}
                btnClassName={cs(style.feature_trait_wrapper)}
              >
                {({ itemProps }) => (
                  <div className={cs(style.feature_trait)}>
                    {itemProps.name.toString()}
                  </div>
                )}
              </InputMultiList>
            </div>
          ))}
      </div>

      {filtersNb > 0 && (
        <div className={cs(style.clear_filters_wrapper)}>
          <button type="button" onClick={() => setFeaturesFilters([])}>
            <span>clear filters</span>
            <span className={cs(style.filters_count)}>{filtersNb}</span>
          </button>
        </div>
      )}
    </>
  )
}
