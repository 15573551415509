import style from "./SettingsGroup.module.scss"
import text from "Styles/Text.module.scss"
import cs from "classnames"
import { PropsWithChildren } from "react"

type Props = PropsWithChildren<{
  title: string
  infos?: string
}>
export function SettingsGroup({ title, infos, children }: Props) {
  return (
    <section className={cs(style.root)}>
      <h3>{title}</h3>
      {infos && <p className={cs(text.infos, style.infos)}>{infos}</p>}
      <div className={cs(style.content)}>{children}</div>
    </section>
  )
}
