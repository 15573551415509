import { Reactor, ReactorEvent } from "Utils/Reactor"
import { INetworkNode, NetworkInterface } from "./NetworkInterface"
import { arrayRemove } from "Utils/array"

type TBaseChannels = "__ping" | "__disconnect"
type WithBase<T extends string> = T | TBaseChannels

interface IInternalNetworkNode extends INetworkNode {
  lastPing: number
}

export class BroadcastChannelNetworkInterface<
  Channels extends string,
> extends NetworkInterface<Channels> {
  // the broadcast channel instance
  _broadcastChannel: BroadcastChannel | null = null
  // keep track of the nodes in the network
  _nodes: IInternalNetworkNode[]
  _connectedNodesHash = ""
  // stores the ping timeout
  _timeout: NodeJS.Timeout | null = null

  constructor(
    name: string,
    role: string,
    id: string,
    reactor: Reactor<Channels>
  ) {
    super(name, role, id, reactor)
    this._broadcastChannel = new BroadcastChannel(name)
    this._nodes = []

    // setup the main listener -> then dispatches formatted event
    this._broadcastChannel.onmessage = event => {
      // if a ping is received, we do not dispatch the event
      if (event.data.channel === "__ping") {
        const found = this._nodes.find(node => node.id === event.data.id)
        if (found) {
          found.lastPing = performance.now()
        } else {
          this._nodes.push({
            id: event.data.id,
            role: event.data.role,
            lastPing: performance.now(),
          })
          this.syncConnectedNodes()
        }
      }
      // if a node broadcasted its deconnexion
      else if (event.data.channel === "__disconnect") {
        const found = this._nodes.find(node => node.id === event.data.id)
        if (found) {
          arrayRemove(this._nodes, found)
          this.syncConnectedNodes()
        }
      }
      // regular event, for others to consume
      else {
        this.reactor.dispatchEvent(
          new ReactorEvent(event.data.channel, event.data.message)
        )
      }
    }

    // set interval to ping the channel about existing (this is how we map the
    // network participants everywhere)
    const ping = () => {
      this._broadcast("__ping")
      this.syncConnectedNodes()
      this._timeout = setTimeout(ping, 1000)
    }
    ping()

    window.onbeforeunload = () => {
      this._broadcast("__disconnect")
    }
  }

  /**
   * Will be called whenever the internal nodes are updated.
   * When called, it will compare the active nodes currently available and if
   * changes are found since last dispatch, it will emit a change in the nodes.
   */
  private syncConnectedNodes() {
    const now = performance.now()
    // filter active nodes, normalize node format
    const nodes = this._nodes
      .filter(node => now - node.lastPing < 10_000)
      .map(node => ({
        id: node.id,
        role: node.role,
      }))
    // if a change is observed, notify of a change in the nodes
    const hash = JSON.stringify(nodes)
    if (hash !== this._connectedNodesHash) {
      this.updateNodes(nodes)
      this._connectedNodesHash = hash
    }
  }

  /**
   * Broadcast a message on the Broadcast channel. The method is private because
   * it implements extra reserved channels for network organisation purposes.
   */
  private _broadcast(channel: WithBase<Channels>, message?: any) {
    this._broadcastChannel?.postMessage({
      id: this.id,
      role: this.role,
      channel,
      message,
    })
  }

  public broadcast = (channel: Channels, message?: any) => {
    this._broadcast(channel, message)
  }

  public disconnect(): void {
    super.disconnect()
    this._broadcast("__disconnect")
    this._broadcastChannel?.close()
    this._broadcastChannel = null
    this._timeout && clearTimeout(this._timeout)
  }
}
