import { NetworkInterface } from "Utils/Network/NetworkInterface"
import { Reactor, ReactorEvent } from "Utils/Reactor"
import { arrayRemove } from "Utils/array"
import { useCallback, useEffect, useMemo, useRef, useState } from "react"

type TBaseChannels = "ping" | "disconnect"
type WithBase<T extends string> = T | TBaseChannels

type TBroadcastMessage<Channels extends string> = (
  channel: Channels,
  message?: any
) => void

export interface IUseChannelConnectionReturn<Channels extends string> {
  broadcast: TBroadcastMessage<Channels>
  reactor: Reactor<Channels>
  network: INetworkNode[]
}

type TNodeRole = "parent" | "target"

interface INetworkNode {
  id: string
  role: TNodeRole
}

/**
 * Can be used to create a connection between 2 instances of the program, using
 * a connection strategy abstracted from this layer.
 * @param networkInterface a factory which can instanciate a network interface
 * @param name the name of the connection - used as an identifier on the network
 * @param role whether this instance will be a parent or a target
 */
export function useRTConnection<Channels extends string>(
  NetworkInterfaceClass: new (...a: any[]) => NetworkInterface<Channels>,
  name: string,
  role: "parent" | "target"
): IUseChannelConnectionReturn<Channels> {
  // unique ID identifying the client on this sparse network
  const id = useMemo(() => Math.random().toString(), [])
  // the nodes connected to the network, updated in RT
  const [nodes, setNodes] = useState<INetworkNode[]>([])
  // the reactor, used for managing events for consumers
  const reactor = useMemo(() => new Reactor<Channels>(), [])

  const networkInterface = useRef<NetworkInterface<Channels> | null>(null)

  useEffect(() => {
    // instanciate the network interfact
    const ni = new NetworkInterfaceClass(name, role, id, reactor)
    networkInterface.current = ni
    // when nodes are updated, update the state
    networkInterface.current._reactor.addEventListener("nodes:update", evt => {
      setNodes(evt.data)
    })
    // when unmounting, disconnect the interface
    return () => {
      ni.disconnect()
    }
  }, [name, role, id, reactor, setNodes])

  const broadcast = useCallback(
    (channel: Channels, message: any) => {
      if (!networkInterface.current) return
      networkInterface.current.broadcast(channel, message)
    },
    [name, role, id, reactor, setNodes]
  )

  return {
    broadcast,
    reactor,
    network: nodes,
  }
}
