import { useRTConnection } from "Hooks/useChannel"
import { GenerativeToken } from "Types/entities/GenerativeToken"
import { TParamintEvents, paramintChannelName } from "./shared"
import { ParamsMinterViewer } from "Components/Viewers/ParamsMinter/Viewer"
import { ErrorMain } from "Components/UI/ErrorMain"
import { BroadcastChannelNetworkInterface } from "Utils/Network/BroadcastChannelNetworkInterface"
import { SocketNetworkInterface } from "Utils/Network/SocketNetworkInterface"
import { ParmsMinterRootSettings } from "./Root"

interface IProps extends ParmsMinterRootSettings {
  token: GenerativeToken
}
export function ParamsMinterTarget({ token, ...settings }: IProps) {
  const networkInterface =
    (settings.socket_connection as any) === "1"
      ? SocketNetworkInterface
      : BroadcastChannelNetworkInterface

  const connection = useRTConnection(
    networkInterface,
    paramintChannelName(token),
    "target"
  )

  // count the number of parent nodes attached to this node
  const parents = connection.network.filter(node => node.role === "parent")
  const nbParents = parents.length

  return nbParents === 0 ? (
    <ErrorMain title={"The controller isn't connected"}>
      Please make sure to have a controller on the network.
    </ErrorMain>
  ) : (
    <ParamsMinterViewer connection={connection} token={token} />
  )
}
