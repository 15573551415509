import style from "./MintProgress.module.scss"
import { GenerativeToken } from "Types/entities/GenerativeToken"

interface MintProgressProps {
  token: GenerativeToken
}

export function MintProgress({ token }: MintProgressProps) {
  const minted = token.supply - token.balance
  const progress = minted / token.supply
  return (
    <>
      <div className={style.progress}>
        <div
          className={style.bar}
          style={{
            width: progress * 100 + "%",
          }}
        />
      </div>
      <div className={style.label}>
        {minted} / {token.supply}
      </div>
    </>
  )
}
