import style from "./InputText.module.scss"
import cs from "classnames"
import { HTMLAttributes } from "react"

interface Props extends Omit<HTMLAttributes<HTMLInputElement>, "onChange"> {
  type?: "text" | "number"
  error?: boolean
  value: string
  onChange: (value: string) => void
}
export function InputText({
  value,
  onChange,
  type = "text",
  error = false,
  ...props
}: Props) {
  return (
    <input
      type={type}
      value={value}
      {...props}
      onChange={evt => onChange(evt.target.value)}
      className={cs(
        style.input,
        {
          [style.error]: error,
        },
        props.className
      )}
    />
  )
}
