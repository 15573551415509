import React, { FunctionComponent } from "react"

export enum EViewerMode {
  PROJECT_CYCLER = "PROJECT_CYCLER",
  RARITY_CYCLER = "RARITY_CYCLER",
  LIVE_MINTING = "LIVE_MINTING",
  RARITY_EXPLORER = "RARITY_EXPLORER",
  PROJECT_GALLERY = "PROJECT_GALLERY",
  LIVE_MINTING_GALLERY = "LIVE_MINTING_GALLERY",
  PARAMS_MINTER = "PARAMS_MINTER",
  PARAMS_MULTI_MINTER = "PARAMS_MULTI_MINTER",
}

export type TSettingType = "number" | "boolean" | "string"

export type TControlProps<T = string> = {
  value: T
  onChange: (value: T) => void
}

export type TControl<T = string | string[]> = FunctionComponent<
  TControlProps<T>
>

export interface IControlDefinition {
  input: TControl<any>
}

export type TControlDefinitions = Record<TSettingType, IControlDefinition>

export interface ISettingDefinition {
  type: TSettingType
  name: string
  id: string
  description?: string
  multiple?: boolean
  default: any | any[]
}
export interface ISettingDefinitionSingle extends ISettingDefinition {
  multiple?: false
  default: any
}
export interface ISettingDefinitionMultiple extends ISettingDefinition {
  multiple: true
  default: any[]
}

export interface ISettingSingle {
  value: any
  definition: ISettingDefinitionSingle
}
export interface ISettingMultiple {
  value: any[]
  definition: ISettingDefinitionMultiple
}
export type ISetting = ISettingSingle | ISettingMultiple
export type TSettings = Record<string, ISetting>

export type TModeRootProps<Settings = any> = {
  settings: Settings
}

export type TModeRoot<Settings = any> = FunctionComponent<
  TModeRootProps<Settings>
>

export interface IViewerModeDefinition {
  name: string
  icon: React.ReactNode
  route: string
  description: string
  settings: ISettingDefinition[]
  root: TModeRoot
}

export type TViewerModeDefinitions = Record<EViewerMode, IViewerModeDefinition>
