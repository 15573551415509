import style from "./GalleryRoot.module.scss"
import { TModeRoot } from "Types/Settings"
import React, { CSSProperties, useMemo } from "react"
import { ShowcaseIteration } from "../../../Components/ShowcaseIteration/ShowcaseIteration"
import { WallCollection } from "../../../Components/WallCollection/WallCollection"
import { Qu_genTokenEntireCollection } from "../../../Queries/Projects"
import { Objkt } from "../../../Types/entities/Objkt"
import { GenerativeToken } from "../../../Types/entities/GenerativeToken"
import { useIndexWithApi } from "../../../Hooks/useIndexWithApi"
import { IQuGenTok } from "../../../Types/queries/GenToksObjkts"
import { computeScoreFxhashNative } from "../../../Utils/rarity"
import { IterationWithRarity } from "./IterationWithRarity"
import { LayoutGallery } from "Containers/Layout/LayoutGallery"

interface ISettings {
  project_id: string
  fading: string
  nb_features: string
  background: string
}
export const GalleryRoot: TModeRoot<ISettings> = ({ settings }) => {
  const { data } = useIndexWithApi<IQuGenTok, GenerativeToken>(
    Qu_genTokenEntireCollection,
    {
      variables: {
        id: parseFloat(settings.project_id),
      },
    },
    input => {
      // filter unsigned editions
      const tok = { ...input.generativeToken }
      tok.entireCollection = tok.entireCollection!.filter(
        gentk => gentk.assigned
      )
      return tok
    }
  )

  const display = useMemo<{
    last: Objkt | null
    iterations: Objkt[]
    count: number
  }>(() => {
    const out = data?.entireCollection
      ? [...data.entireCollection].sort((a, b) => b.iteration! - a.iteration!)
      : undefined
    if (out) {
      const iterationsWithRarity = out ? computeScoreFxhashNative(out) : []
      const [last, ...rest] = iterationsWithRarity
      return {
        last,
        iterations: iterationsWithRarity,
        count: iterationsWithRarity.length || 0,
      }
    }
    return {
      last: null,
      iterations: [],
      count: 0,
    }
  }, [data])

  const tokenWithRarity = useMemo(() => {
    if (!data) return null
    return {
      ...data,
      entireCollection: display.iterations,
    }
  }, [data, display])

  // maximum number of features
  const maxFeatures =
    settings.nb_features !== "-1" ? parseInt(settings.nb_features) : undefined

  return (
    <LayoutGallery
      className={style.container}
      settings={settings}
      leftColumn={
        <>
          {display.iterations.length > 0 && (
            <WallCollection
              iterations={display.iterations}
              IterationDisplay={IterationWithRarity}
            />
          )}
        </>
      }
      rightColumn={
        <>
          {display.last && tokenWithRarity && (
            <div className={style.last_minted}>
              <ShowcaseIteration
                token={tokenWithRarity}
                label="Just Minted:"
                objkt={display.last}
                showRarity="image"
                totalIterations={display.count}
                maxNbFeatures={maxFeatures}
                showRarityDistribution
                hasVerticalSpace
                greyBorder
              />
            </div>
          )}
        </>
      }
      classNameRight={style.rightColumn}
    />
  )
}
