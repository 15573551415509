import { useEffect } from "react"
import useFetch, { CachePolicies } from "use-http"

const LIVE_MINTING_ROUTES = {
  free: "/free-live-minting-url",
  paid: "/live-minting-url",
}

export const useFetchLiveMintingUrl = (settings: {
  event_id?: string
  event_password?: string
  mint_pass_group_address?: string
}) => {
  const {
    post,
    data: url,
    loading,
    error,
  } = useFetch(process.env.REACT_APP_EVENTS_API, {
    cachePolicy: CachePolicies.NETWORK_ONLY,
  })

  const fetchLiveMintingUrl = async () => {
    if (!settings.mint_pass_group_address || !settings.event_password) return
    const url = await post(LIVE_MINTING_ROUTES.paid, {
      mintPassGroupAddress: settings.mint_pass_group_address,
      eventPassword: settings.event_password,
    })
    return url
  }

  const fetchFreeLiveMintingUrl = async () => {
    if (!settings.event_id) return
    const url = await post(LIVE_MINTING_ROUTES.free, {
      eventId: settings.event_id,
      eventPassword: settings.event_password,
    })
    return url
  }

  const fetch = settings.mint_pass_group_address
    ? fetchLiveMintingUrl
    : fetchFreeLiveMintingUrl

  useEffect(() => {
    if (!settings) return
    fetch()
  }, [
    settings.event_password,
    settings.event_id,
    settings.mint_pass_group_address,
  ])

  return {
    url,
    loading,
    error,
    refetch: fetch,
  }
}
