import { useLayoutEffect, useRef } from "react"

interface ICallbackParam {
  time: number
  dt: number
}
type TCallback = (time: ICallbackParam) => void

// Reusable component that also takes dependencies
export function useAnimationFrame(cb: TCallback) {
  const cbRef = useRef<TCallback>()
  const frame = useRef<number>()
  const init = useRef(performance.now())
  const last = useRef(performance.now())

  cbRef.current = cb

  const animate: FrameRequestCallback = now => {
    // In seconds ~> you can do ms or anything in userland
    cbRef.current?.({
      time: (now - init.current) / 1000,
      dt: (now - last.current) / 1000,
    })
    last.current = now
    frame.current = requestAnimationFrame(animate)
  }

  useLayoutEffect(() => {
    frame.current = requestAnimationFrame(animate)
    return () => {
      frame.current && cancelAnimationFrame(frame.current)
    }
  }, [])
}
