import css from "./ProjectSelector.module.scss"
import cs from "classnames"
import { Image } from "Components/Image"
import { GenerativeToken } from "Types/entities/GenerativeToken"
import { getAuthorNames, getUserName } from "Utils/user"

interface ProjectProps {
  token: GenerativeToken
  onClick: () => void
}
function Project({ token, onClick }: ProjectProps) {
  return (
    <div className={cs(css.project)} onClick={onClick}>
      <div className={cs(css["image-wrapper"])}>
        <Image
          image={token.captureMedia}
          alt={token.name}
          className={cs(css.preview)}
        />
        <img src={token.captureMedia!.placeholder} className={cs(css.blur)} />
      </div>
      <div>
        <h3 className={cs(css.title)}>{token.name}</h3>
        <span>{getAuthorNames(token.author)}</span>
      </div>
    </div>
  )
}

interface Props {
  tokens: GenerativeToken[]
  onSelect: (token: GenerativeToken) => void
}

export function ProjectSelector({ tokens, onSelect }: Props) {
  console.log({ tokens })
  return (
    <div className={cs(css.root)}>
      {tokens.map(tok => (
        <Project key={tok.id} token={tok} onClick={() => onSelect(tok)} />
      ))}
    </div>
  )
}
