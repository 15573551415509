import { Image } from "Components/Image"
import style from "./IterationWithOwner.module.scss"
import { IterationDisplayProps } from "Components/WallCollection/DisplayVertical"
import { truncateMiddle } from "Utils/strings"

export function IterationWithOwner({ objkt }: IterationDisplayProps) {
  return (
    <>
      <Image
        alt=""
        image={objkt.captureMedia}
        ipfsUri={objkt.metadata?.thumbnailUri}
        mode="responsive"
      />
      <figcaption className={style.caption}>
        #{objkt.iteration} minted by&nbsp;
        {objkt.owner?.name || truncateMiddle(objkt.owner?.id || "", 12)}
      </figcaption>
    </>
  )
}
