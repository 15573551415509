export interface TimeoutTracked {
  timeout: NodeJS.Timeout
  start: number
  end: number
  getRemainingTime: () => number
}
export function setTimeoutTracked<TArgs extends any[]>(
  callback: (...args: TArgs) => void,
  ms?: number,
  ...args: TArgs
): TimeoutTracked {
  return {
    timeout: setTimeout<TArgs>(callback, ms, ...args),
    start: performance.now(),
    end: performance.now() + (ms || 0),
    getRemainingTime: function () {
      return this.end - performance.now()
    },
  }
}
