import style from "./RarityExplorerRoot.module.scss"
import { TModeRoot } from "Types/Settings"
import { useQuery } from "@apollo/client"
import {
  Qu_genTokensObjkts,
  Qu_genTokensRarityExplorer,
} from "../../../Queries/Projects"
import { Loader } from "../../../Components/Utils/Loader"
import cs from "classnames"
import { useCallback, useMemo, useState } from "react"
import {
  GenerativeToken,
  GenerativeTokenFeature,
} from "../../../Types/entities/GenerativeToken"
import { GentkPicker } from "./GentkPicker"
import { IObjktFeatureFilter, Objkt } from "../../../Types/entities/Objkt"
import { Gallery } from "./Gallery"
import { useIndexWithApi } from "Hooks/useIndexWithApi"
import { IQuGenToksObjkts } from "Types/queries/GenToksObjkts"
import { enhanceGenerativeTokenWithFeatures } from "Utils/features"

type Output = GenerativeToken[]

interface ISettings {
  projects_id: string[]
}
export const RarityExplorerRoot: TModeRoot<ISettings> = ({ settings }) => {
  const { data, loading } = useIndexWithApi<IQuGenToksObjkts, Output>(
    Qu_genTokensObjkts,
    {
      variables: {
        filters: {
          id_in: settings.projects_id.map(id => parseInt(id)),
        },
      },
    },
    (input, current) => {
      const out: GenerativeToken[] = []
      const tokens = input.generativeTokens
      for (const idStr of settings.projects_id) {
        const id = parseInt(idStr)
        let found = tokens.find(token => token.id === id)
        if (!found) found = current?.find(token => token.id === id)
        if (found) {
          // filter out the unsigned iterations
          out.push({
            ...found,
            entireCollection: found.entireCollection!.filter(t => t.assigned),
          })
        }
      }
      return out
    }
  )

  // we compile a list of every feature, for every project
  const tokensWithFeatures = useMemo(() => {
    const tokens = data
    if (!tokens) return null
    return tokens.map(token => enhanceGenerativeTokenWithFeatures(token))
  }, [data])

  const [tokenSelected, setTokenSelected] = useState<GenerativeToken | null>(
    null
  )
  const [featuresFilters, setFeaturesFilters] = useState<IObjktFeatureFilter[]>(
    []
  )
  const handleSelect = useCallback((newSelected: GenerativeToken) => {
    setFeaturesFilters([])
    setTokenSelected(newSelected)
  }, [])

  if (loading && !tokensWithFeatures) {
    return (
      <div className={cs(style.container, style.centered_loader)}>
        <Loader />
      </div>
    )
  }

  return (
    <div className={style.container}>
      {tokensWithFeatures && (
        <>
          <GentkPicker
            className={style.gentk_picker}
            tokens={tokensWithFeatures}
            onSelect={handleSelect}
            selected={tokenSelected}
            featuresFilters={featuresFilters}
            setFeaturesFilters={setFeaturesFilters}
          />
        </>
      )}
      <div className={style.gallery}>
        {tokenSelected && (
          <Gallery token={tokenSelected} featuresFilters={featuresFilters} />
        )}
      </div>
    </div>
  )
}
