import style from "./WallCollection.module.scss"
import { ComponentType, memo, useContext, useMemo } from "react"
import { Objkt } from "../../Types/entities/Objkt"
import { DisplayVertical, IterationDisplayProps } from "./DisplayVertical"
import { SettingsContext } from "Context/SettingsContext"

interface WallCollectionProps {
  iterations: Objkt[]
  IterationDisplay: ComponentType<IterationDisplayProps>
}

const _WallCollection = ({
  iterations,
  IterationDisplay,
}: WallCollectionProps) => {
  const settings = useContext(SettingsContext)
  const cols = Math.min(iterations.length, parseInt(settings.nb_cols))

  // distribute the iterations within the columns
  const colsIterations = useMemo<Objkt[][]>(() => {
    // sort the iterations by their iteration #, ensuring same distrib
    const sorted = iterations.sort((a, b) => a.id - b.id)
    const out: Objkt[][] = [...Array(cols)].map(_ => [])
    for (let i = 0; i < sorted.length; i++) {
      out[i % cols].push(sorted[i])
    }
    return out
  }, [iterations, cols])

  // variations of the scroll speed
  const speedCols = useMemo(() => {
    const variation = parseInt(settings.scroll_speed_variation) / 100
    const speed = parseInt(settings.screen_time)
    return [...Array(cols)].map(
      _ => speed + (Math.random() - 0.5) * variation * speed
    )
  }, [cols])

  return (
    <div className={style.container_cols}>
      {colsIterations.map((objkts, idx) => {
        const direction = idx % 2 === 0 ? "top" : "bottom"
        return (
          <DisplayVertical
            iterations={objkts}
            key={idx}
            speed={(speedCols[idx] / 60) * 100}
            direction={direction}
            IterationDisplay={IterationDisplay}
          />
        )
      })}
    </div>
  )
}

export const WallCollection = memo(_WallCollection)
