import {
  GenerativeToken,
  GenerativeTokenFeature,
} from "Types/entities/GenerativeToken"
import { IObjktFeatureFilter, Objkt } from "Types/entities/Objkt"

/**
 * Takes a Generative Token with an entireCollection field defined, and returns
 * a Generative Token with the features field populated with data from the
 * features
 */
export function enhanceGenerativeTokenWithFeatures(
  token: GenerativeToken
): GenerativeToken {
  const features: GenerativeTokenFeature[] = []
  for (const gentk of token.entireCollection!) {
    if (gentk.features) {
      for (const feature of gentk.features) {
        const N = feature.name
        const V = feature.value
        // find the feature in the list, if not create it
        let featureFound = features.find(f => f.name === N)
        if (!featureFound) {
          featureFound = {
            name: N,
            values: [],
          }
          features.push(featureFound)
        }
        // find the value in the list, if not create it
        const found = featureFound.values.find(val => val.value === V)
        if (!found) {
          featureFound.values.push({
            value: V,
            occur: 1,
          })
        } else {
          found.occur++
        }
      }
    }
  }
  return {
    ...token,
    features: features,
  }
}

/**
 * Given a set of gentks, applies the filter on the set to output the gentks
 * filtered from the pass.
 */
export function filterGentks(
  gentks: Objkt[],
  filters: IObjktFeatureFilter[]
): Objkt[] {
  let out = gentks
  // for every filter in the list, we apply its effects
  for (const filter of filters) {
    out = out.filter(gentk => {
      if (!gentk.features) return false
      // get the feature of the gentk
      const feature = gentk.features.find(f => filter.name === f.name)
      // if it doesn't have the feature, it's filtered out
      if (!feature) return false
      // check if it's one of the provided values
      return filter.values.includes(feature.value as any)
    })
  }
  return out
}
