import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faWarning } from "@fortawesome/free-solid-svg-icons"
import style from "./ErrorMain.module.scss"
import cs from "classnames"
import { PropsWithChildren } from "react"

interface Props {
  title: string
}
export function ErrorMain({ title, children }: PropsWithChildren<Props>) {
  return (
    <div className={cs(style.root)}>
      <div className={cs(style.main)}>
        <FontAwesomeIcon icon={faWarning} size="3x" />
        <h1>{title}</h1>
        {children && <div className={cs(style.message)}>{children}</div>}
      </div>
    </div>
  )
}
