import style from "./GentkFeatures.module.scss"
import cs from "classnames"
import { Objkt } from "Types/entities/Objkt"
import { useMemo } from "react"
import { RarityBadge } from "./RarityBadge"

interface Props {
  gentk: Objkt
  maxFeatures?: number
  showScore?: boolean
}
export function GentkFeatures({ gentk, maxFeatures, showScore = true }: Props) {
  const rarityNb = ((gentk.fxhashRarity || 0) * 100).toFixed(1)

  const features = useMemo(() => {
    if (typeof maxFeatures === "undefined") return gentk.features!
    return gentk.features!.slice(0, maxFeatures)
  }, [gentk, maxFeatures])

  return (
    <div className={style.features}>
      {showScore && (
        <div className={style.big_feature}>
          <div>Rarity Score</div>
          <RarityBadge>{rarityNb}%</RarityBadge>
        </div>
      )}
      {features?.map(feature => (
        <div className={style.feature} key={feature.name}>
          <div className={cs(style.value)}>
            <small>{feature.name}</small>
            <div>{feature.value.toString()}</div>
          </div>
          <div className={style.rarity}>
            {((feature.rarity || 0) * 100).toFixed(1)}%
          </div>
        </div>
      ))}
    </div>
  )
}
