import style from "./Root.module.scss"
import { TModeRoot } from "Types/Settings"
import React, { CSSProperties, useMemo } from "react"
import {
  Qu_genTokenEntireCollection,
  Qu_genTokens,
} from "../../../Queries/Projects"
import { GenerativeToken } from "../../../Types/entities/GenerativeToken"
import { LoaderBlock } from "Components/Layout/LoaderBlock"
import { useSearchParams } from "react-router-dom"
import { useQuery } from "@apollo/client"
import { ParamsMultiMinterController } from "./Controller"
import { ParamsMultiMinterTarget } from "./Target"
import { ErrorMain } from "Components/UI/ErrorMain"
import { SharedParamsMinterSettings } from "Components/Viewers/ParamsMinter/shared"

export interface ParamsMultiMinterSettings extends SharedParamsMinterSettings {
  projects_id: string[]
}
export const ParamsMultiMinterRoot: TModeRoot<ParamsMultiMinterSettings> = ({
  settings,
}) => {
  // check whether this instance is a controller or target
  const [queryParams] = useSearchParams()
  const isController = queryParams.get("context") !== "target"
  const Comp = useMemo(
    () =>
      isController ? ParamsMultiMinterController : ParamsMultiMinterTarget,
    [isController]
  )

  const { data, loading } = useQuery<{ generativeTokens: GenerativeToken[] }>(
    Qu_genTokens,
    {
      variables: {
        filters: {
          id_in: settings.projects_id.map(str => parseInt(str)),
        },
      },
      fetchPolicy: "network-only",
    }
  )

  const tokens = data?.generativeTokens

  return (
    <div className={style.root}>
      {loading ? (
        <LoaderBlock height="100vh" />
      ) : tokens && tokens.length > 0 ? (
        <Comp tokens={tokens} settings={settings} />
      ) : (
        <ErrorMain title="Errors fetching the tokens">
          The tokens provided ({settings.projects_id.join(", ")}) could not be
          fetched, please make sure the list is correct.
        </ErrorMain>
      )}
    </div>
  )
}
