import { Reactor, ReactorEvent } from "Utils/Reactor"

export type TNodeRole = "parent" | "target"

export interface INetworkNode {
  id: string
  role: TNodeRole
}

type NetworkInterfaceChannels = "nodes:update"

export abstract class NetworkInterface<Channels extends string> {
  name: string
  role: string
  id: string
  // apllication-related reactor -> main applications events dispatched here
  reactor: Reactor<Channels>
  // a list of the nodes currently available on the network
  nodes: INetworkNode[] = []
  // reactor used internally to dispatch network-related events
  _reactor: Reactor<NetworkInterfaceChannels>

  constructor(
    name: string,
    role: string,
    id: string,
    reactor: Reactor<Channels>
  ) {
    this.name = name
    this.role = role
    this.id = id
    this.reactor = reactor
    this._reactor = new Reactor<NetworkInterfaceChannels>()
  }

  /**
   * Sub-classes should call this method to reflect when a change has been
   * noticed in the nodes currently in the network. This method will update the
   * internal pointer as well as emit an event consumers can listen to apply
   * reactive changes to the nodes
   * @param nodes a list of the new nodes on the network
   */
  protected updateNodes(nodes: INetworkNode[]) {
    this.nodes = nodes
    this._reactor.dispatchEvent(new ReactorEvent("nodes:update", this.nodes))
  }

  /**
   * Broadcast a message to the other network participants, on the provided
   * channel. The message may get lost, this shouldn't implement any answer
   * strategy.
   * @param channel the string identifier of the channel to broadcast a message
   * @param message the message to be broadcasted
   */
  public abstract broadcast(channel: Channels, message?: any): void

  /**
   * When the connection needs to be ended manually, this method can be called
   * by consumers
   */
  public disconnect() {
    this._reactor.removeAllEventListeners()
  }
}
