import {
  ButtonHTMLAttributes,
  InputHTMLAttributes,
  SelectHTMLAttributes,
} from "react"
import classes from "./BaseInput.module.scss"
import cx from "classnames"

export type InputSize = "normal" | "large"

export interface BaseInputProps extends InputHTMLAttributes<HTMLInputElement> {
  inputSize?: InputSize
}

export function BaseInput(props: BaseInputProps) {
  const { className, inputSize = "normal", ...rest } = props
  return (
    <input
      className={cx(classes.baseInput, classes[`size-${inputSize}`], className)}
      {...rest}
    />
  )
}

export interface BaseSelectProps
  extends SelectHTMLAttributes<HTMLSelectElement> {
  inputSize?: InputSize
}

export function BaseSelect(props: BaseSelectProps) {
  const { className, inputSize = "normal", ...rest } = props
  return (
    <select
      className={cx(
        classes.baseSelect,
        classes[`size-${inputSize}`],
        className
      )}
      {...rest}
    />
  )
}

export interface BaseButtonProps
  extends ButtonHTMLAttributes<HTMLButtonElement> {
  color?: "primary" | "secondary" | "primary-inverted" | "main"
  inputSize?: InputSize
}

export function BaseButton(props: BaseButtonProps) {
  const { className, color = "primary", inputSize = "normal", ...rest } = props
  return (
    <button
      type="button"
      className={cx(
        classes.baseButton,
        classes[`size-${inputSize}`],
        classes[`color-${color}`],
        className
      )}
      {...rest}
    />
  )
}

export function IconButton(props: BaseButtonProps) {
  const { className, ...rest } = props
  return <BaseButton className={cx(classes.iconButton, className)} {...rest} />
}
