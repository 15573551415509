import { useCallback, useEffect, useRef, useState } from "react"
import { GenerativeToken } from "Types/entities/GenerativeToken"
import { IMintOperation } from "Services/Indexing/ContractHandlers/IssuerHandler"
import { IProjectIteration } from "Types/Project"
import { LiveRevealView } from "./LiveRevealView"
import { TimeoutTracked, setTimeoutTracked } from "Utils/time"
import { LiveRevealProgress } from "./LiveRevealProgress"

interface ProjectLiveProps {
  delayMs: number
  hasDebug?: boolean
  hasKeyboardControls?: boolean
  tokenIterations: IProjectIteration[] | null
  dual?: boolean
}

export function ProjectLiveReveal({
  delayMs,
  tokenIterations,
  dual,
}: ProjectLiveProps) {
  const [cursor, setCursor] = useState(0)
  const [iterations, setIterations] = useState<IProjectIteration[]>()
  // a timeout where time elapsed is tracked
  const timeout = useRef<TimeoutTracked | null>(null)
  // a reference to the iteration currently displayed
  const iterationDisplayed = useRef<IProjectIteration | null>(null)
  const progressBar = useRef<HTMLDivElement | null>(null)

  // whenever new mints are received from the blockchain they are processed in
  // here, so that piles are sorted properly
  useEffect(() => {
    if (!tokenIterations) return
    // no iterations -> we are initializing, everything is set as "viewed"
    if (!iterations) {
      setIterations(
        tokenIterations.map(iteration => ({
          ...iteration,
          viewed: true,
        }))
      )
      // we also move the cursor at the latest minted iteration
      setCursor(tokenIterations.length - 1)
    }
    // add iterations to the pile of existing iterations, set those as "not
    // viewed"
    else {
      setIterations(
        tokenIterations.map(iteration => ({
          ...iteration,
          viewed: false,
        }))
      )
    }
  }, [tokenIterations])

  // update the cursor, but clamps it at the end of the iterations array
  const moveCursor = useCallback(
    (shift: -1 | 1, allowOverflow = false) => {
      if (!iterations) return
      const limit = iterations.length - (allowOverflow ? 0 : 1)
      const newCursor = Math.max(0, Math.min(cursor + shift, limit))
      if (newCursor !== cursor) {
        setCursor(newCursor)
      }
    },
    [iterations, setCursor, cursor]
  )

  // add event listeners to move the cursor manually
  useEffect(() => {
    const listener = (event: KeyboardEvent) => {
      switch (event.key) {
        case "ArrowLeft":
          moveCursor(-1, true)
          break
        case " ":
        case "ArrowRight":
          moveCursor(1, true)
          break
      }
    }
    window.addEventListener("keydown", listener)
    return () => {
      window.removeEventListener("keydown", listener)
    }
  }, [moveCursor])

  // whenever the iterations / cursor changes, we trigger the setTimeout if
  // an iteration is pointed by the cursor
  // if a timeout is already active, we cancel it and we use the delay left
  // on the current iteration
  useEffect(() => {
    const iteration = iterations?.[cursor]
    // if there is no active iteration, we ignore
    if (!iteration) return

    let delay = delayMs

    // if there is already a timeout running, we use its remaining delay to
    // trigger the next timeout, ensuring a clear data flow
    // shift is only reapplied if the same iteration is going to be displayed
    if (
      timeout.current &&
      iterationDisplayed.current?.iteration === iteration.iteration
    ) {
      delay = Math.max(0, delayMs - timeout.current.getRemainingTime())
    }

    // if there is a change of iteration displayed, quick hack to reset the
    // progress bar
    if (iterationDisplayed.current?.iteration !== iteration.iteration) {
      if (progressBar.current) {
        const progressBarClass = progressBar.current.className
        progressBar.current.className = ""
        setTimeout(() => {
          progressBar.current &&
            (progressBar.current.className = progressBarClass)
        }, 20)
      }
    }

    // update pointer to iteration displayed
    iterationDisplayed.current = iteration

    // we set a timeout to move to the next iteration
    timeout.current = setTimeoutTracked(() => {
      console.log("move cursor")
      moveCursor(1)
    }, delay)

    // cancel timeout
    return () => {
      console.log("clear timeout")
      timeout.current && clearTimeout(timeout.current.timeout)
    }
  }, [iterations, cursor, moveCursor, delayMs])

  const cursorEven = Math.floor((cursor + 1) / 2) * 2 - 1
  const cursorOdd = Math.floor(cursor / 2) * 2

  return (
    <>
      <LiveRevealView iteration={iterations?.[dual ? cursorEven : cursor]} />
      {dual && <LiveRevealView iteration={iterations?.[cursorOdd]} />}
      <LiveRevealProgress delay={delayMs} ref={progressBar} />
    </>
  )
}
