import { arrayRemove } from "./array"

export class ReactorEvent<Channels extends string> {
  channel: Channels
  data: any

  constructor(channel: Channels, data: any) {
    this.channel = channel
    this.data = data
  }
}

type TListener<Channels extends string> = (
  event: ReactorEvent<Channels>
) => void

/**
 * Implements a simple Reactor pattern, to subscribe to events and dispatch
 * events to listeners.
 * https://en.wikipedia.org/wiki/Reactor_pattern
 */
export class Reactor<Channels extends string> {
  private _listeners: Record<string, TListener<Channels>[]> = {}

  /**
   * Add a listener to a given event
   * @param channel channel of the event to subsribe to
   * @param listener callback which will be called when dispatching an event
   */
  addEventListener(channel: Channels, listener: TListener<Channels>) {
    if (!this._listeners[channel]) this._listeners[channel] = []
    if (!this._listeners[channel].includes(listener)) {
      this._listeners[channel].push(listener)
    }
    return listener
  }

  /**
   * Remove a listener from a given event
   * @param channel channel of the event the listener is subscribed to
   * @param listener the callback which was subscribed previously
   */
  removeEventListener(channel: Channels, listener: TListener<Channels>) {
    if (this._listeners[channel]?.includes(listener)) {
      this._listeners[channel] = arrayRemove(this._listeners[channel], listener)
    }
  }

  /**
   * Dispatch an event on a channel to all the listeners of the channel
   * @param event the event to dispatch to all the listeners
   */
  dispatchEvent(event: ReactorEvent<Channels>) {
    if (this._listeners[event.channel]) {
      this._listeners[event.channel].forEach(listener => listener(event))
    }
  }

  /**
   * Remove all the event listeners
   */
  removeAllEventListeners() {
    this._listeners = {}
  }
}
