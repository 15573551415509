import { TIndexQueryParameters } from "Types/Indexing"

const TZKT_API = process.env.REACT_APP_TZKT_API

export const API_BLOCKCHAIN_CONTRACT_STORAGE = (address: string) =>
  `${TZKT_API}contracts/${address}/storage`

export const API_BLOCKCHAIN_CONTRACT_DETAILS = (address: string) =>
  `${TZKT_API}contracts/${address}`

export const API_CYCLES_LIST = `${TZKT_API}bigmaps/updates\
?contract=${process.env.NEXT_PUBLIC_TZ_CT_ADDRESS_CYCLES}\
&path=cycles\
&action=add_key\
&limit=500`

export const API_OPERATION = (hash: string) => `${TZKT_API}operations/${hash}`

export const API_BLOCKCHAIN_CONTRACT_OPERATIONS = (
  address: string,
  cursorId: number,
  entrypoints: string[],
  queryParameters: TIndexQueryParameters = {},
  limit = 1000
) =>
  `${TZKT_API}operations/transactions\
?target=${address}\
&entrypoint.in=${entrypoints.join(",")}\
&offset.cr=${cursorId}\
&status=applied\
&select=id,level,timestamp,sender,amount,parameter,diffs,target,initiator,hash\
&limit=${limit}`.concat(
    Object.keys(queryParameters)
      .map(param => `&${param}=${queryParameters[param]}`)
      .join("")
  )
