import style from "./RevealIframe.module.scss"
import cs from "classnames"
import { forwardRef, useState, useEffect } from "react"
import { LoaderBlock } from "../Layout/LoaderBlock"

interface Props {
  url?: string
  onLoaded?: () => void
  resetOnUrlChange?: boolean
  className?: string
  classNameIframeContainer?: string
  animation?: "rotate" | "fade"
}
export const RevealIframe = forwardRef<HTMLIFrameElement, Props>(
  (
    {
      className,
      classNameIframeContainer,
      url,
      onLoaded,
      resetOnUrlChange = false,
      animation = "rotate",
    },
    ref
  ) => {
    const [loaded, setLoaded] = useState(false)

    useEffect(() => {
      if (resetOnUrlChange) {
        setLoaded(false)
      }
    }, [resetOnUrlChange, url])

    const isLoaded = () => {
      setTimeout(() => {
        setLoaded(true)
        onLoaded?.()
      }, 500)
    }

    return (
      <div className={cs(style.container, className)}>
        <div
          className={cs(
            style.iframe_container,
            classNameIframeContainer,
            style[`animation-${animation}`],
            {
              [style.loaded]: loaded,
            }
          )}
        >
          <iframe
            title={`reveal`}
            ref={ref}
            sandbox="allow-scripts allow-same-origin"
            onLoad={() => isLoaded()}
            // onReset
            src={url}
          />
          <LoaderBlock
            height="100%"
            className={cs(style.loader)}
            color="gray"
          />
        </div>
      </div>
    )
  }
)
