import style from "./WallCollection.module.scss"
import { ComponentType, memo, useMemo, useRef } from "react"
import { Objkt } from "../../Types/entities/Objkt"
import { Image } from "../Image"
import cs from "classnames"
import { useAnimationFrame } from "Hooks/useAnimationFrame"
import { RarityBadge } from "Components/Gentk/RarityBadge"

// minimum number of items per column
const MIN_PER_COL = 8

export type IterationDisplayProps = {
  objkt: Objkt
  index: number
}

interface DisplayVerticalProps {
  iterations: Objkt[]
  speed?: number
  direction: "top" | "bottom"
  IterationDisplay: ComponentType<IterationDisplayProps>
}

const _DisplayVertical = ({
  iterations,
  speed = 100,
  direction,
  IterationDisplay,
}: DisplayVerticalProps) => {
  const container = useRef<HTMLDivElement>(null)
  const duplicates = useRef<number>(1)

  // to ensure we have enough iterations on the screen, we duplicated the
  // element as many times as required.
  // the elements are also duplicated twice to ensure we can loop even if there
  // are enough elements to fill the column
  const looping = useMemo(() => {
    // how many times do we duplicate
    const dups = Math.max(2, Math.ceil(MIN_PER_COL / iterations.length))
    // duplicate the array
    let out: Objkt[] = []
    for (let i = 0; i < dups; i++) {
      out = out.concat(iterations)
    }
    duplicates.current = dups
    return out
  }, [iterations])

  useAnimationFrame(time => {
    const cont = container.current
    const dups = duplicates.current || 2

    if (cont) {
      // speed is in % of the screen height / min
      const S = speed * 0.01 * window.innerHeight // pixels per second
      const pxMin = S / 60 // pixels per min

      // offset is derived from time, we keep offset synced with time
      let offset = time.time * pxMin

      // loop based on the unduplicated column height (works cause div at end)
      const H = cont.getBoundingClientRect().height
      offset = offset % (H / dups)

      // direction of the offset
      offset *= direction === "top" ? 1 : -1
      // if direction is bottom, we offset from viewport height
      offset += direction === "bottom" ? H - window.innerHeight : 0

      cont.style.transform = `translateY(${-offset}px)`
    }
  })

  return (
    <div className={style.container}>
      <div ref={container} className={cs(style.col, style[`col_${direction}`])}>
        {looping.map((objkt, idx) => (
          <div
            key={`${objkt.id}-${idx}`}
            className={cs(style.iteration, `box-${idx}`)}
          >
            <IterationDisplay objkt={objkt} index={idx} />
          </div>
        ))}
        <div /> {/* easy hack to add 1 gutter at the end, makes math easier */}
      </div>
    </div>
  )
}

export const DisplayVertical = memo(_DisplayVertical)
