import style from "./Root.module.scss"
import { TModeRoot } from "Types/Settings"
import React, { CSSProperties, useMemo } from "react"
import { Qu_genTokenEntireCollection } from "../../../Queries/Projects"
import { GenerativeToken } from "../../../Types/entities/GenerativeToken"
import { LoaderBlock } from "Components/Layout/LoaderBlock"
import { useSearchParams } from "react-router-dom"
import { useQuery } from "@apollo/client"
import { ParamsMinterController } from "./Controller"
import { ParamsMinterTarget } from "./Target"
import { ErrorMain } from "Components/UI/ErrorMain"
import { SharedParamsMinterSettings } from "Components/Viewers/ParamsMinter/shared"

export interface ParmsMinterRootSettings extends SharedParamsMinterSettings {
  project_id: string
}

export const ParamsMinterRoot: TModeRoot<ParmsMinterRootSettings> = ({
  settings,
}) => {
  // check whether this instance is a controller or target
  const [queryParams] = useSearchParams()
  const isController = queryParams.get("context") !== "target"
  const Comp = useMemo(
    () => (isController ? ParamsMinterController : ParamsMinterTarget),
    [isController]
  )

  const { data, loading } = useQuery<{ generativeToken: GenerativeToken }>(
    Qu_genTokenEntireCollection,
    {
      variables: {
        id: parseFloat(settings.project_id),
      },
      fetchPolicy: "network-only",
    }
  )

  const token = data?.generativeToken

  return (
    <div className={style.root}>
      {loading ? (
        <LoaderBlock height="100vh" />
      ) : token ? (
        <Comp token={token} {...settings} />
      ) : (
        <ErrorMain title="Token not found">
          We could not fetch token #{settings.project_id}, please make sure the
          ID you've entered is correct.
        </ErrorMain>
      )}
    </div>
  )
}
