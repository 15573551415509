import {
  DocumentNode,
  LazyQueryHookOptions,
  useLazyQuery,
} from "@apollo/client"
import { useState } from "react"
import { IQuGenToksObjkts } from "Types/queries/GenToksObjkts"
import { useAsyncInterval } from "./useInterval"

type TMergeFunction<TInput, TOutput> = (
  input: TInput,
  current?: TOutput
) => TOutput

type TUseIndexWithApiReturn<TOutput> = {
  data?: TOutput
  loading: boolean
}

/**
 * This component is designed to index some data with the main API
 */
export function useIndexWithApi<TInput = any, TOutput = any>(
  query: DocumentNode,
  options?: LazyQueryHookOptions,
  merge: TMergeFunction<TInput, TOutput> = (a, b) => a as any as TOutput
): TUseIndexWithApiReturn<TOutput> {
  const [indexedData, setIndexedData] = useState<TOutput>()
  const [fetch, { loading }] = useLazyQuery<TInput>(query, {
    ...options,
    fetchPolicy: "network-only",
  })

  useAsyncInterval(
    async isMounted => {
      try {
        const { data, error } = await fetch()
        if (error) throw error
        if (!data) throw Error("No data when fetching")
        const merged = merge(data, indexedData)
        if (isMounted()) {
          setIndexedData(merged)
        }
      } catch (err) {
        console.log("Error when executing useIndexWithApi query:")
        console.error(err)
      }
    },
    10000,
    true,
    [indexedData]
  )

  return {
    data: indexedData,
    loading: loading,
  }
}
