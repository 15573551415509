import { Icon } from "Components/UI/Icon"
import { CyclerRoot } from "Containers/Modes/Cycler/CyclerRoot"
import { GalleryRoot } from "Containers/Modes/Gallery/GalleryRoot"
import { LiveMintingGallery } from "Containers/Modes/LiveMintingGallery/LiveMintingGalleryRoot"
import { LiveRevealRoot } from "Containers/Modes/LiveReveal/LiveRevealRoot"
import { ParamsMinterRoot } from "Containers/Modes/ParamsMinter/Root"
import { ParamsMultiMinterRoot } from "Containers/Modes/ParamsMultiMinter/Root"
import { RarityCyclerRoot } from "Containers/Modes/RarityCycler/Root"
import { RarityExplorerRoot } from "Containers/Modes/RarityExplorer/RarityExplorerRoot"
import {
  EViewerMode,
  ISettingDefinition,
  TViewerModeDefinitions,
} from "Types/Settings"

export const ViewerModes = Object.values(EViewerMode)

// reusable settings
const projectIdSetting: ISettingDefinition = {
  type: "number",
  name: "Project ID",
  id: "project_id",
  description: "Enter the ID of the project",
  default: "",
}
const projectsIdSetting: ISettingDefinition = {
  type: "number",
  multiple: true,
  name: "Project IDs",
  id: "projects_id",
  description: "Enter ID of the projects",
  default: "",
}
const delaySetting: ISettingDefinition = {
  type: "number",
  name: "Time per iteration (ms)",
  id: "delay",
  description:
    "The delay before a new iteration is displayed, in milliseconds (ms)",
  default: "25000",
}
const queryParamsSetting: ISettingDefinition = {
  id: "display_query_params",
  name: "Display query params:",
  description: "Query params to append to the display URL, e.g. foo=bar",
  type: "string",
  default: "",
}

const liveMintingSettings: ISettingDefinition[] = [
  {
    type: "string",
    id: "mint_pass_group_address",
    name: "Mint pass group address (optional)",
    description:
      "To display a QR code for PAID live minting, provide the address of a mint pass group contract.",
    default: "",
  },
  {
    type: "string",
    id: "event_id",
    name: "Event ID (optional)",
    description:
      "To display a QR code for FREE live minting, provide the ID of the event.",
    default: "",
  },
  {
    type: "string",
    id: "event_password",
    name: "Event password (optional)",
    description: "Required if mint pass address or event ID is provided.",
    default: "",
  },
]

// settings for minting interface with fxparams (control UI)
const paramsMintingSettings: ISettingDefinition[] = [
  {
    type: "boolean",
    id: "socket_connection",
    name: "Internet socket connection",
    description:
      "Should the connection between the interface and the view go through internet (required if running on different machines)",
    default: "0",
  },
  {
    type: "string",
    id: "force_mode",
    name: "Mode",
    description:
      "(auto|params|classic) auto will auto-detect, others will force a mode which can be useful for slow pieces",
    default: "auto",
  },
]

export const ViewerModeDefinitions: TViewerModeDefinitions = {
  PROJECT_CYCLER: {
    name: "Project Cycler",
    route: "cycler",
    description: "Cycle through the iterations of a project, one by one.",
    icon: <Icon icon="fa-sharp fa-solid fa-rectangle-vertical-history" />,
    settings: [projectIdSetting, delaySetting, queryParamsSetting],
    root: CyclerRoot,
  },
  RARITY_CYCLER: {
    name: "Rarity Cycler",
    route: "rarity-cycler",
    description:
      "Cycle through the iterations of a project, with rarity displayed at the bottom",
    icon: <Icon icon="fa-sharp fa-solid fa-sparkles" />,
    settings: [
      projectIdSetting,
      delaySetting,
      {
        id: "load_iframe",
        type: "boolean",
        name: "Load <iframe>",
        description:
          "The item in the center will be replaced by an <iframe> when reaching the center",
        default: "0",
      },
    ],
    root: RarityCyclerRoot,
  },
  LIVE_MINTING: {
    name: "Live Minting Reveal",
    route: "reveal",
    description: "Display iterations of a project minted in real time.",
    icon: <Icon icon="fa-sharp fa-solid fa-rectangle-vertical-history" />,
    settings: [
      projectsIdSetting,
      {
        id: "dual_view",
        name: "Dual view",
        description: "Display two reveal feeds in columns next to each other",
        type: "boolean",
        default: "0",
      },
      delaySetting,
      {
        id: "display_minter",
        name: "Display minter",
        description: "Display the minter's name or address under the title",
        type: "boolean",
        default: "1",
      },
      {
        id: "display_title",
        name: "Display title",
        description: "Display the title of the piece",
        type: "boolean",
        default: "1",
      },
      {
        id: "display_progress",
        name: "Display progress bar",
        description:
          "Display a bar at the bottom which shows the progress of the delay",
        type: "boolean",
        default: "1",
      },
      {
        id: "bg_color",
        name: "Background color",
        description: "The hexadecimal representation of the color",
        type: "string",
        default: "#1d2227",
      },
      {
        id: "display_aspect_ratio",
        name: "Display orientation",
        description:
          "The orientation of the display, 0 for square, 1 for portrait, 2 for landscape",
        type: "number",
        default: "0",
      },
      {
        id: "display_minimal",
        name: "Minimalistic styling",
        description: "First seen on NFCL 2023",
        type: "boolean",
        default: "0",
      },
      {
        id: "rotate_animation",
        name: "Rotate animation",
        description:
          "Use fx(hash) signature rotate animation. false = fade in only",
        type: "boolean",
        default: "1",
      },
      queryParamsSetting,
    ],
    root: LiveRevealRoot,
  },
  PROJECT_GALLERY: {
    name: "Project Gallery",
    route: "gallery",
    description:
      "Displays multiple iterations of a project on a screen, with a slow motion of the column.",
    icon: <Icon icon="fa-sharp fa-solid fa-grid" />,
    settings: [
      projectIdSetting,
      {
        type: "number",
        id: "screen_time",
        name: "Screen time",
        description:
          "Time during which an iteration stays on the screen (in seconds)",
        default: 60,
      },
      {
        type: "number",
        id: "scroll_speed_variation",
        name: "Scroll speed variation",
        description: "How much each column speed can differ (in %)",
        default: 10,
      },
      {
        type: "number",
        id: "nb_cols",
        name: "Number of columns",
        default: 3,
      },
      {
        type: "boolean",
        id: "fading",
        name: "Fading columns",
        default: false,
      },
      {
        type: "number",
        id: "nb_features",
        name: "Max nb. of features displayed",
        description:
          "How many features at most are displayed on the right (-1 = display all)",
        default: -1,
      },
      {
        type: "string",
        id: "background",
        name: "Background color",
        description:
          "The 6-digits hexadecimal representation of the color, without the #",
        default: "1d2227",
      },
    ],
    root: GalleryRoot,
  },
  LIVE_MINTING_GALLERY: {
    name: "Live Minting Gallery",
    route: "live-minting-gallery",
    description:
      "Displays the iterations of a project on a screen, with QR code for live minting",
    icon: <Icon icon="fa-sharp fa-regular fa-qrcode" />,
    settings: [
      projectIdSetting,
      ...liveMintingSettings,
      {
        type: "number",
        id: "screen_time",
        name: "Screen time",
        description:
          "Time during which an iteration stays on the screen (in seconds)",
        default: 60,
      },
      {
        type: "number",
        id: "scroll_speed_variation",
        name: "Scroll speed variation",
        description: "How much each column speed can differ (in %)",
        default: 10,
      },
      {
        type: "number",
        id: "nb_cols",
        name: "Number of columns",
        default: 3,
      },
      {
        type: "boolean",
        id: "fading",
        name: "Fading columns",
        default: false,
      },
      {
        type: "string",
        id: "background",
        name: "Background color",
        description:
          "The 6-digits hexadecimal representation of the color, without the #",
        default: "1d2227",
      },
      {
        type: "number",
        id: "num_chars_trunc",
        name: "Max characters of project description",
        description:
          "Truncate description at number of characters. (0 = no truncation)",
        default: 0,
      },
    ],
    root: LiveMintingGallery,
  },
  RARITY_EXPLORER: {
    name: "Rarity Explorer",
    route: "rarity-explorer",
    description:
      "Designed to work for touch devices, lets visitors explore the rarity of multiple projects.",
    icon: <Icon icon="fa-sharp fa-solid fa-gallery-thumbnails" />,
    settings: [projectsIdSetting],
    root: RarityExplorerRoot,
  },
  PARAMS_MINTER: {
    name: "Params Minter",
    route: "params-minter",
    description:
      "For interactive installations with a touch device to modulate parameters.",
    icon: <Icon icon="fa-solid fa-sliders" />,
    settings: [
      projectIdSetting,
      ...paramsMintingSettings,
      ...liveMintingSettings,
    ],
    root: ParamsMinterRoot,
  },
  PARAMS_MULTI_MINTER: {
    name: "Params Multi Minter",
    route: "params-multi-minter",
    description:
      "For interactive installations with a touch device to modulate parameters, where multiple projects are displayed on the UI",
    icon: <Icon icon="fa-solid fa-sliders" />,
    settings: [
      projectsIdSetting,
      ...paramsMintingSettings,
      ...liveMintingSettings,
    ],
    root: ParamsMultiMinterRoot,
  },
}
