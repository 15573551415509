import { gql } from "@apollo/client"
import { Frag_UserBadge } from "./fragments/user"
import { Frag_MediaImage } from "./fragments/media"

export const Qu_genTokens = gql`
  ${Frag_MediaImage}
  query Basel_GenerativeTokens($filters: GenerativeTokenFilter) {
    generativeTokens(filters: $filters) {
      id
      name
      supply
      metadata
      generativeUri
      captureMedia {
        ...MediaImage
      }
      supply
      author {
        id
        name
        avatarUri
        type
        collaborators {
          id
          type
          name
          avatarUri
        }
      }
    }
  }
`

export const Qu_genTokensRarityExplorer = gql`
  query ($filters: GenerativeTokenFilter) {
    generativeTokens(filters: $filters) {
      id
      name
      supply
      features
      thumbnailUri
    }
  }
`

export const Qu_genTokenFeatures = gql`
  query GenerativeTokenFeatures($id: Float) {
    generativeToken(id: $id) {
      id
      features
    }
  }
`

export const Qu_genTokenIterations = gql`
  ${Frag_MediaImage}
  ${Frag_UserBadge}
  query GenerativeTokenIterations(
    $id: Float
    $take: Int
    $skip: Int
    $sort: ObjktsSortInput
    $featureFilters: [FeatureFilter!]
  ) {
    generativeToken(id: $id) {
      id
      features
      objktsCount
      supply
      objkts(
        take: $take
        skip: $skip
        sort: $sort
        featureFilters: $featureFilters
      ) {
        id
        version
        iteration
        issuer {
          flag
          labels
        }
        owner {
          ...UserBadgeInfos
        }
        name
        metadata
        features
        rarity
        captureMedia {
          ...MediaImage
        }
      }
    }
  }
`

export const Qu_genTokensObjkts = gql`
  ${Frag_MediaImage}
  ${Frag_UserBadge}

  query GenerativeTokensWithObjkts($filters: GenerativeTokenFilter) {
    generativeTokens(filters: $filters) {
      id
      name
      thumbnailUri
      supply
      captureMedia {
        ...MediaImage
      }
      entireCollection {
        id
        assigned
        version
        iteration
        generationHash
        issuer {
          generativeUri
        }
        owner {
          ...UserBadgeInfos
        }
        name
        metadata
        features
        rarity
        captureMedia {
          ...MediaImage
        }
      }
    }
  }
`

export const Qu_genTokenEntireCollection = gql`
  ${Frag_MediaImage}
  ${Frag_UserBadge}

  query GenerativeTokenEntireCollection($id: Float!) {
    generativeToken(id: $id) {
      id
      name
      metadata
      author {
        id
        name
      }
      supply
      thumbnailUri
      generativeUri
      supply
      balance
      pricingFixed {
        price
        opensAt
      }
      pricingDutchAuction {
        levels
        restingPrice
        finalPrice
        decrementDuration
        opensAt
      }
      captureMedia {
        ...MediaImage
      }
      entireCollection {
        id
        version
        iteration
        displayUri
        assigned
        generationHash
        issuer {
          generativeUri
        }
        owner {
          ...UserBadgeInfos
        }
        name
        metadata
        features
        rarity
        captureMedia {
          ...MediaImage
        }
      }
    }
  }
`
