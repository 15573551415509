import { Checkbox } from "Components/Input/Checkbox"
import { TControl } from "Types/Settings"

/**
 * Simple wrapper arround the Checkbox component to implement the specs of the
 * Control interface while using existing checkbox component
 */
export const ControlBoolean: TControl<string> = ({ value, onChange }) => {
  return (
    <Checkbox
      value={value === "1"}
      onChange={val => onChange(val ? "1" : "0")}
    />
  )
}
