import css from "./Waiting.module.scss"
import cs from "classnames"

interface Props {
  title?: string
}
export function Waiting({ title }: Props) {
  return (
    <div className={cs(css.root)}>
      <img src="/images/fx.png" alt="fx" />
      {title && <span className={cs(css.title)}>{title}</span>}
      <span className={cs(css.fx)}>fx</span>
    </div>
  )
}
