import { useQuery } from "@apollo/client"
import { Qu_genTokens } from "Queries/Projects"
import { useMemo } from "react"
import { GenerativeToken } from "../Types/entities/GenerativeToken"

export function useFetchProjects(ids: number[]) {
  const { data } = useQuery<{ generativeTokens: GenerativeToken[] }>(
    Qu_genTokens,
    {
      variables: {
        filters: {
          id_in: ids,
        },
      },
    }
  )

  // when there's data, outputs the generative token json for each project
  return useMemo<GenerativeToken[]>(() => {
    if (!data) {
      return [] as GenerativeToken[]
    }
    return ids
      .map(id => data.generativeTokens.find(token => token.id === id))
      .filter(gen => gen) as GenerativeToken[]
  }, [data, ids])
}
