import {
  ApolloClient,
  ApolloProvider,
  FieldFunctionOptions,
  InMemoryCache,
} from "@apollo/client"
import { Route, Routes } from "react-router-dom"
import "./App.module.scss"
import { SettingsRoot } from "./Settings/SettingsRoot"
import { ViewerModeDefinitions } from "Definitions/Settings"
import { ModeRootWrapper } from "./Modes/ModeRootWrapper"
import { EViewerMode } from "Types/Settings"
import { FastLinksRoot } from "./FastLinksRoot"

/**
 * Given a set of existing data, incoming data and pagination arguments,
 * merges incoming with existing **while ignoring incoming duplicates already
 * stored in existing**.
 */
export function cacheMergePaginatedField(
  existing: any[] = [],
  incoming: any[],
  { args }: FieldFunctionOptions<any>
): any[] {
  // shallow copy existing array
  const merged = [...existing]
  const { skip } = args || { skip: 0 }
  let j = 0
  mainLoop: for (let i = 0; i < incoming.length; ++i) {
    // we check for duplicates in the existing cache
    if (existing) {
      for (const item of existing) {
        // if there's  duplicate, we ignore the incoming one and continue
        if (incoming[i].__ref === item.__ref) {
          continue mainLoop
        }
      }
    }
    // add the incoming to the merge array
    merged[skip + j++] = incoming[i]
  }
  return merged
}

const client = new ApolloClient({
  uri: process.env.REACT_APP_FXHASH_API,
  cache: new InMemoryCache({
    typePolicies: {
      GenerativeToken: {
        fields: {
          objkts: {
            keyArgs: ["sort", "featureFilters", "filters"],
            merge: cacheMergePaginatedField,
          },
        },
      },
    },
  }),
})

function App() {
  return (
    <ApolloProvider client={client}>
      <Routes>
        {Object.entries(ViewerModeDefinitions).map(([mode, def]) => (
          <Route
            key={def.route}
            path={def.route}
            element={
              <ModeRootWrapper mode={mode as EViewerMode} element={def.root} />
            }
          />
        ))}
        <Route path="/fast-links" element={<FastLinksRoot />} />
        <Route path="/" element={<SettingsRoot />} />
      </Routes>
    </ApolloProvider>
  )
}

export default App
