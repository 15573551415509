import { useEffect, useState } from "react"
import styles from "./RefreshProgressBar.module.scss"

interface RefreshProgressBarProps {
  interval: number
  onRefreshCallback?: () => Promise<void>
}

const RefreshProgressBar = ({
  interval,
  onRefreshCallback,
}: RefreshProgressBarProps) => {
  const [countdown, setCountdown] = useState(interval)

  useEffect(() => {
    // set a timer to update the progress bar every second
    const timer = setInterval(() => {
      // if the countdown is not 0, decrement it
      if (countdown > 0) setCountdown(countdown - 1)
    }, 1000)

    // if the countdown is 0, refresh the progress bar
    if (countdown === 0) handleRefresh()

    return () => clearInterval(timer)
  }, [countdown])

  const handleRefresh = async () => {
    // if a callback is provided, call it
    if (onRefreshCallback) await onRefreshCallback()
    // reset the countdown
    setCountdown(interval)
  }

  // reset countdown if interval changes
  useEffect(() => {
    setCountdown(interval)
  }, [interval])

  return (
    <div className={styles.container}>
      <div
        className={styles.progress_bar}
        style={{
          width: `${(countdown - 1) * ((100 + 100 / interval) / interval)}%`,
        }}
      />
      <p className={styles.label}>Refreshing in {countdown} seconds</p>
    </div>
  )
}

export { RefreshProgressBar }
