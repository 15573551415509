import { ipfsGatewayUrl } from "./ipfs"

export function getLiveUrl(
  generativeUri: string,
  options: {
    hash: string
    queryParams?: string
    inputBytes?: string
    minterAddress?: string
  }
) {
  let url = `${ipfsGatewayUrl(generativeUri)}/?fxhash=${options.hash}`
  if (options.inputBytes) url += `&fxparams=${options.inputBytes}`
  if (options.minterAddress) url += `&fxminter=${options.minterAddress}`
  if (options.queryParams) url += `&${options.queryParams}`
  return url
}

const charSet = "123456789abcdefghijkmnopqrstuvwxyzABCDEFGHJKLMNPQRSTUVWXYZ"
export const generateFxHash = () =>
  "oo" +
  Array(49)
    .fill(0)
    .map(_ => charSet[(Math.random() * charSet.length) | 0])
    .join("")

export function isHashValid(hash: string): boolean {
  if (hash.length !== 51) {
    return false
  }
  for (let i = 0; i < hash.length; i++) {
    if (!charSet.includes(hash[i])) return false
  }
  return true
}
