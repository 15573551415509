import { Reactor, ReactorEvent } from "Utils/Reactor"
import { NetworkInterface } from "./NetworkInterface"
import { Socket, io } from "socket.io-client"

/**
 * The SocketNetworkInterface provides communication between different instances
 * using the dedicated socket server.
 */
export class SocketNetworkInterface<
  Channels extends string,
> extends NetworkInterface<Channels> {
  _socket: Socket

  constructor(
    name: string,
    role: string,
    id: string,
    reactor: Reactor<Channels>
  ) {
    super(name, role, id, reactor)
    this._socket = io(process.env.REACT_APP_EVENTS_LIVE_BACKEND, {
      query: {
        name,
        role,
        id,
      },
    })

    this._socket.on("__sockets", data => {
      this.updateNodes(data.clients)
    })

    this._socket.on("message", data => {
      reactor.dispatchEvent(new ReactorEvent(data.channel, data.message))
    })
  }

  public broadcast = (channel: Channels, message?: any) => {
    this._socket.emit("message", {
      channel,
      message,
    })
  }

  public disconnect(): void {
    super.disconnect()
    this._socket.disconnect()
  }
}
