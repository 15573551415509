import style from "./ShowcaseIteration.module.scss"
import cs from "classnames"
import React, { memo, useMemo } from "react"
import { Objkt } from "../../Types/entities/Objkt"
import { Image } from "../Image"
import { UserBadge } from "../User/UserBadge"
import { ArtworkFrame } from "../Artwork/ArtworkFrame"
import { getLiveUrl } from "../../Utils/fxhash"
import { GentkFeatures } from "Components/Gentk/GentkFeatures"
import { RarityBadge } from "Components/Gentk/RarityBadge"
import { GenerativeToken } from "Types/entities/GenerativeToken"
import { sum } from "Utils/math"
import { toPercentage } from "Utils/format"

interface ShowcaseIterationProps {
  token: GenerativeToken
  objkt: Objkt
  label: string
  totalIterations: number
  showLive?: boolean
  showRarity?: "image" | "description"
  maxNbFeatures?: number
  showRarityDistribution?: boolean
  featuresShowScore?: boolean
  hasVerticalSpace?: boolean
  greyBorder?: boolean
}

const _ShowcaseIteration = ({
  token,
  objkt,
  label,
  totalIterations,
  showLive,
  showRarity = "description",
  maxNbFeatures,
  showRarityDistribution = false,
  featuresShowScore = true,
  hasVerticalSpace = false,
  greyBorder = false,
}: ShowcaseIterationProps) => {
  const rarityNb = ((objkt.fxhashRarity || 0) * 100).toFixed(2) + "%"
  const liveUrl = useMemo(
    () =>
      getLiveUrl(objkt.issuer.generativeUri!, { hash: objkt.generationHash! }),
    [objkt]
  )

  // find the rarest, average and least rare values
  const { rarest, average, leastRare } = useMemo(() => {
    const sorted = token.entireCollection!.sort(
      (a, b) => a.fxhashRarity! - b.fxhashRarity!
    )
    return {
      rarest: sorted[0].fxhashRarity!,
      average: sum(sorted.map(o => o.fxhashRarity!)) / sorted.length,
      leastRare: sorted[sorted.length - 1].fxhashRarity!,
    }
  }, [token])

  return (
    <div
      className={cs(style.container, {
        [style.vertical_space]: hasVerticalSpace,
      })}
    >
      <div>
        {showLive ? (
          <div className={style.frame_wrapper}>
            <ArtworkFrame url={liveUrl} />
          </div>
        ) : (
          <div className={style.image_wrapper}>
            <div className={style.img_center}>
              <Image
                alt=""
                image={objkt.captureMedia}
                ipfsUri={objkt.metadata?.thumbnailUri}
                mode="contain"
              />
            </div>
          </div>
        )}
        <div className={cs(style.iteration_details)}>
          <div>
            <div className={style.iteration}>
              <span>
                {label} #{objkt.iteration}/{token.supply}
              </span>
            </div>
            {objkt.owner && (
              <div className={style.owner}>
                <div className={style.owner_label}>Owned by</div>
                <span className={cs(style.badge_wrapper)}>
                  {"("}
                  <UserBadge
                    displayAvatar={false}
                    hasLink={false}
                    user={objkt.owner}
                  />
                  {")"}
                </span>
              </div>
            )}
          </div>
          <div>
            {showRarity === "image" && (
              <RarityBadge className={style.rarity_badge}>
                {rarityNb}
              </RarityBadge>
            )}
          </div>
        </div>
      </div>
      <div
        className={cs(style.features_wrapper, {
          [style.grey_border]: greyBorder,
        })}
      >
        {showRarityDistribution ? (
          <div className={cs(style.rarity_distrib)}>
            <div className={cs(style.title)}>Collection Stats</div>
            <div className={cs(style.distrib_entry)}>
              <span>Most Rare</span>
              <span>{toPercentage(rarest)}</span>
            </div>
            <div className={cs(style.distrib_entry)}>
              <span>Average Rarity</span>
              <span>{toPercentage(average)}</span>
            </div>
            <div className={cs(style.distrib_entry)}>
              <span>Least Rare</span>
              <span>{toPercentage(leastRare)}</span>
            </div>
          </div>
        ) : (
          <GentkFeatures
            gentk={objkt}
            maxFeatures={maxNbFeatures}
            showScore={featuresShowScore}
          />
        )}
      </div>
    </div>
  )
}

export const ShowcaseIteration = memo(_ShowcaseIteration)
