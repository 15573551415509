import { createRef, useEffect, useMemo, useState } from "react"
import { consolidateParams, stringifyParamsData } from "./utils"
import { ParameterController } from "./Controller/Param"
import { LockButton } from "./LockButton/LockButton"
import classes from "./Controls.module.scss"
import { validateParameterDefinition } from "./validation"
import { FxParamControllerSize } from "./Controller/Controller"
import cx from "classnames"
import { FxParamType, FxParamTypeMap } from "./types"

const TwoColumns = ({ array }: { array: any[] }) => {
  const midIndex = Math.ceil(array.length / 2)
  const firstColumn = array.slice(0, midIndex)
  const secondColumn = array.slice(midIndex)

  return (
    <>
      <div style={{ flex: 1 }}>
        {firstColumn.map((item, index) => (
          <div key={index}>{item}</div>
        ))}
      </div>
      <div style={{ flex: 1 }}>
        {secondColumn.map((item, index) => (
          <div key={index}>{item}</div>
        ))}
      </div>
    </>
  )
}

interface ControllerBladeProps {
  parameter: any
  onClickLockButton?: (id: string) => void
  lockedParamIds?: string[]
  onChangeParam: (id: string, value: any) => void
  size?: FxParamControllerSize
}

function ControllerBlade(props: ControllerBladeProps) {
  const {
    parameter,
    onClickLockButton,
    lockedParamIds,
    onChangeParam,
    size = "normal",
  } = props
  const parsed = useMemo(
    () => validateParameterDefinition(parameter),
    [parameter]
  )
  const isValid = useMemo(() => parsed && parsed.success, [parsed])
  return (
    <div className={cx(classes.blade, classes[`size-${size}`])}>
      <ParameterController
        parameter={parameter}
        value={parameter.value}
        onChange={onChangeParam}
        size={size}
      />
      {onClickLockButton && isValid && (
        <LockButton
          className={classes.lockButton}
          title={`toggle lock ${parameter.id} param`}
          isLocked={lockedParamIds?.includes(parameter.id)}
          onClick={e => onClickLockButton(parameter.id)}
        />
      )}
    </div>
  )
}

export type ControlsOnChangeDataHandler = (
  newData: Record<string, any>,
  changedParam?: {
    id: string
    value: FxParamTypeMap[FxParamType]
  }
) => void

interface ControlsProps {
  params: any
  onClickLockButton?: (id: string) => void
  lockedParamIds?: string[]
  onChangeData: ControlsOnChangeDataHandler
  data: Record<string, any>
  size?: FxParamControllerSize
}

export const Controls = ({
  params,
  data,
  onClickLockButton,
  lockedParamIds,
  onChangeData,
  size = "normal",
}: ControlsProps) => {
  const consolidatedParams = consolidateParams(params, data)

  const p: React.RefObject<HTMLDivElement> = createRef()

  useEffect(() => {
    const ps: any = {}
    if (consolidatedParams?.length > 0) {
      consolidatedParams.forEach((p: any) => {
        ps[p.id] = p.value
      })
      if (stringifyParamsData(data) !== stringifyParamsData(ps))
        onChangeData(ps)
    }
  }, [params])

  const handleChangeParam = (id: string, value: any) => {
    const newData = { ...data, [id]: value }
    onChangeData(newData, { id, value })
  }

  const controllerBlades = consolidatedParams?.map((p: any) => {
    return (
      <ControllerBlade
        key={p.id}
        parameter={p}
        onChangeParam={handleChangeParam}
        lockedParamIds={lockedParamIds}
        onClickLockButton={onClickLockButton}
        size={size}
      />
    )
  })

  return (
    <div className={cx(classes.controls, classes[`size-${size}`])} ref={p}>
      {size === "large" ? (
        <TwoColumns array={controllerBlades} />
      ) : (
        controllerBlades
      )}
    </div>
  )
}
