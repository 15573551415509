import { IUseChannelConnectionReturn } from "Hooks/useChannel"
import { GenerativeToken } from "Types/entities/GenerativeToken"
import { TSharedParamsMinterEvent } from "./shared"
import { useEffect, useMemo, useRef, useState } from "react"
import {
  ArtworkIframeRef,
  ArtworkIframeWithFrame,
} from "Components/Artwork/ArtworkIframeWithRef"
import { gentkUrl } from "Utils/objkt"
import { useReceiveTokenInfos } from "Hooks/useReceiveTokenInfos"

interface IProps<Events extends string> {
  token: GenerativeToken
  connection: IUseChannelConnectionReturn<Events | TSharedParamsMinterEvent>
}
export function ParamsMinterViewer<Events extends string>({
  token,
  connection,
}: IProps<Events>) {
  const { reactor, broadcast } = connection
  const { previewHash, previewMinter, previewInputBytes } = token.metadata
  const iframeRef = useRef<ArtworkIframeRef>(null)
  const {
    setHash,
    hash,
    setMinter,
    minter,
    setParams,
    paramsDefinition,
    params,
    onIframeLoaded,
  } = useReceiveTokenInfos(iframeRef, {
    initialHash: previewHash,
    initialMinter: previewMinter,
  })

  const [inputBytes, setInputBytes] = useState<string>(previewInputBytes || "")

  useEffect(() => {
    const listener = reactor.addEventListener("inputs:update", evt => {
      const { hash, minter, inputBytes } = evt.data
      setHash(hash)
      setMinter(minter)
      setInputBytes(inputBytes)
    })
    const listener2 = reactor.addEventListener("params:refresh-soft", evt => {
      const { id, value } = evt.data
      iframeRef?.current?.getHtmlIframe()?.contentWindow?.postMessage(
        {
          id: "fxhash_params:update",
          data: {
            params: {
              [id]: value,
            },
          },
        },
        "*"
      )
    })
    const listener3 = reactor.addEventListener(
      "params:definition:request",
      () => {
        broadcast("params:definition:update", { paramsDefinition, params })
      }
    )
    return () => {
      reactor.removeEventListener("params:definition:update", listener)
      reactor.removeEventListener("params:refresh-soft", listener2)
      reactor.removeEventListener("params:definition:update", listener3)
    }
  }, [reactor, paramsDefinition, params])

  // when the params definition changes, sends event to controller
  useEffect(() => {
    broadcast("params:definition:update", { paramsDefinition, params })
  }, [JSON.stringify(paramsDefinition)])

  const url = useMemo(
    () => gentkUrl(token.generativeUri!, hash, minter, inputBytes),
    [token, hash, minter, inputBytes]
  )

  return (
    <ArtworkIframeWithFrame
      url={url}
      ref={iframeRef}
      onLoaded={onIframeLoaded}
    />
  )
}
