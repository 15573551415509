import { IUseChannelConnectionReturn, useRTConnection } from "Hooks/useChannel"
import { GenerativeToken } from "Types/entities/GenerativeToken"
import { TParamulmintEvents, paramulmintChannelName } from "./shared"
import { PropsWithChildren, useEffect, useMemo, useState } from "react"
import { LoaderBlock } from "Components/Layout/LoaderBlock"
import { ProjectSelector } from "./ProjectSelector"
import { ParamsMinterControls } from "Components/Viewers/ParamsMinter/Controls"
import { ParamsMultiMinterSettings } from "./Root"
import { TSharedParamsMinterEvent } from "Components/Viewers/ParamsMinter/shared"
import { BroadcastChannelNetworkInterface } from "Utils/Network/BroadcastChannelNetworkInterface"
import { SocketNetworkInterface } from "Utils/Network/SocketNetworkInterface"

interface Props {
  tokens: GenerativeToken[]
  settings: ParamsMultiMinterSettings
}
export function ParamsMultiMinterController({ tokens, settings }: Props) {
  const networkInterface =
    (settings.socket_connection as any) === "1"
      ? SocketNetworkInterface
      : BroadcastChannelNetworkInterface
  const connection = useRTConnection<TParamulmintEvents>(
    networkInterface,
    paramulmintChannelName(tokens),
    "parent"
  )
  const { broadcast, network } = connection

  const [selected, setSelected] = useState<GenerativeToken | null>(null)

  useEffect(() => {
    !selected && broadcast("project:select", null)
  }, [selected])

  const targetUrl = useMemo(
    () => `${location.pathname}${location.search}&context=target`,
    [location]
  )

  // check if there is a target instance connected
  const hasTarget = network.some(node => node.role === "target")

  const handleGoBackToProjectSelector = () => {
    setSelected(null)
  }

  return hasTarget ? (
    selected ? (
      <ControlsWrapper token={selected} connection={connection}>
        <ParamsMinterControls
          connection={connection}
          paramsDefinition={[]}
          token={selected}
          onGoBack={handleGoBackToProjectSelector}
          {...settings}
        />
      </ControlsWrapper>
    ) : (
      <ProjectSelector tokens={tokens} onSelect={setSelected} />
    )
  ) : (
    <LoaderBlock height="100vh">
      <div>
        open{" "}
        <a href={targetUrl} target="_blank">
          {targetUrl}
        </a>{" "}
        in a new window
      </div>
    </LoaderBlock>
  )
}

/**
 * The Controld Wrapper ensures another depth of sync with the Selection on the
 * target, in case one was reloaded without the other -> this way consitency
 * is preserved.
 */
interface PropsWrapper {
  token: GenerativeToken
  connection: IUseChannelConnectionReturn<
    TParamulmintEvents | TSharedParamsMinterEvent
  >
}
function ControlsWrapper(props: PropsWithChildren<PropsWrapper>) {
  useEffect(() => {
    props.connection.broadcast("project:select", props.token)
  }, [props.token])
  return <>{props.children}</>
}
