import { useEffect, useRef } from "react"

type TEventHandler = (event: any) => void

export function useEventListener(
  eventName: string,
  handler: TEventHandler,
  disable: boolean,
  element: any = window
) {
  const savedHandler = useRef<TEventHandler>()

  useEffect(() => {
    savedHandler.current = handler
  }, [handler])

  useEffect(
    () => {
      if (disable) return
      const isSupported = element && element.addEventListener
      if (!isSupported) return

      const eventListener = (event: any) => savedHandler.current?.(event)

      element.addEventListener(eventName, eventListener)
      return () => {
        element.removeEventListener(eventName, eventListener)
      }
    },
    [disable, eventName, element] // Re-run if eventName or element changes
  )
}
