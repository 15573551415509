import { DependencyList, useEffect, useRef } from "react"
import { useIsMounted } from "./useIsMounted"

export const useInterval = (
  callback: () => void,
  intervalMs: number,
  triggerStart = false,
  dependencies: DependencyList = []
) => {
  const triggered = useRef(false)
  const isMounted = useIsMounted()
  const lastTrigger = useRef<number>(performance.now())

  useEffect(() => {
    if (triggerStart && !triggered.current) {
      lastTrigger.current = performance.now()
      callback()
      triggered.current = true
    }

    const interval = setInterval(
      () => {
        lastTrigger.current = performance.now()
        if (isMounted()) callback()
      },
      Math.min(
        intervalMs,
        Math.max(intervalMs - (performance.now() - lastTrigger.current), 0)
      )
    )

    return () => {
      clearInterval(interval)
    }
  }, dependencies)
}

export const useAsyncInterval = (
  callback: (isMounted: () => boolean) => Promise<void>,
  intervalMs: number,
  triggerStart = false,
  dependencies?: DependencyList
) => {
  const started = useRef(false)
  const isMounted = useIsMounted()

  useEffect(() => {
    if (triggerStart && !started.current) {
      callback(isMounted).then(() => {
        started.current = true
      })
    }

    const interval = setInterval(() => {
      if (isMounted()) callback(isMounted)
    }, intervalMs)

    return () => {
      clearInterval(interval)
    }
  }, dependencies)
}
