import style from "./LiveMintingGalleryRoot.module.scss"
import { TModeRoot } from "Types/Settings"
import React, { useMemo } from "react"
import { WallCollection } from "../../../Components/WallCollection/WallCollection"
import { Qu_genTokenEntireCollection } from "../../../Queries/Projects"
import { Objkt } from "../../../Types/entities/Objkt"
import { GenerativeToken } from "../../../Types/entities/GenerativeToken"
import { useIndexWithApi } from "../../../Hooks/useIndexWithApi"
import { IQuGenTok } from "../../../Types/queries/GenToksObjkts"
import { computeScoreFxhashNative } from "../../../Utils/rarity"
import { IterationWithOwner } from "./IterationWithOwner"
import { truncateEnd, truncateMiddle } from "Utils/strings"
import { MintProgress } from "Components/Gentk/MintProgress"
import { LayoutGallery } from "Containers/Layout/LayoutGallery"
import { QRCode } from "Components/QRCode/QRCode"
import { RefreshProgressBar } from "Components/RefreshProgressBar/RefreshProgressBar"
import { useFetchLiveMintingUrl } from "Hooks/useFetchLiveMintingUrl"
import nl2br from "react-nl2br"

const REFRESH_INTERVAL = 10 // seconds
export interface ISettings {
  project_id: string
  fading: string
  background: string
  num_chars_trunc: string
  mint_pass_group_address: string
  event_id: string
  event_password: string
}
export const LiveMintingGallery: TModeRoot<ISettings> = ({ settings }) => {
  const { data } = useIndexWithApi<IQuGenTok, GenerativeToken>(
    Qu_genTokenEntireCollection,
    {
      variables: {
        id: parseFloat(settings.project_id),
      },
    },
    input => {
      // filter unsigned editions
      const tok = { ...input.generativeToken }
      tok.entireCollection = tok.entireCollection!.filter(
        gentk => gentk.assigned
      )
      return tok
    }
  )

  const { url, error, refetch } = useFetchLiveMintingUrl(settings)

  const iterations = useMemo<Objkt[]>(() => {
    const out = data?.entireCollection
      ? [...data.entireCollection].sort((a, b) => b.iteration! - a.iteration!)
      : undefined
    if (out) {
      const iterationsWithRarity = out ? computeScoreFxhashNative(out) : []
      return iterationsWithRarity
    }
    return []
  }, [data])

  return (
    <LayoutGallery
      settings={settings}
      leftColumn={
        <>
          {iterations.length > 0 && (
            <WallCollection
              iterations={iterations}
              IterationDisplay={IterationWithOwner}
            />
          )}
        </>
      }
      rightColumn={
        <>
          {!!data && (
            <>
              <h2>
                {data.author?.name || truncateMiddle(data.author?.id || "", 12)}
              </h2>
              <h1>{data.name}</h1>
              <MintProgress token={data} />
              <p className={style.description}>
                {nl2br(
                  settings.num_chars_trunc === "0"
                    ? data.metadata.description
                    : truncateEnd(
                        data.metadata.description || "",
                        +settings.num_chars_trunc
                      )
                )}
              </p>
              {error && (
                <p className={style.error}>
                  {url?.error ||
                    "Unknown error occurred loading live minting token"}
                </p>
              )}
              {!error && url && (
                <div className={style.qrWrapper}>
                  <QRCode className={style.qr} url={url} />
                  <RefreshProgressBar
                    interval={REFRESH_INTERVAL}
                    onRefreshCallback={refetch}
                  />
                </div>
              )}
            </>
          )}
        </>
      }
      classNameRight={style.rightColumn}
    />
  )
}
