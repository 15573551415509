import style from "./Button.module.scss"
import cs from "classnames"
import { ButtonHTMLAttributes } from "react"

type Props = ButtonHTMLAttributes<HTMLButtonElement>
export function Button({ type = "button", ...props }: Props) {
  return (
    <button
      type={type}
      {...props}
      className={cs(style.button, props.className)}
    >
      {props.children}
    </button>
  )
}
