import { forwardRef } from "react"
import style from "./LiveRevealProgress.module.scss"
import cs from "classnames"

interface LiveRevealProgressProps {
  delay: number
}

export const LiveRevealProgress = forwardRef<
  HTMLDivElement,
  LiveRevealProgressProps
>(({ delay }, ref) => {
  return (
    <div className={cs(style.progres_bar)}>
      <div
        ref={ref}
        className={cs(style.bar)}
        style={{
          animationDuration: `${delay | 0}ms`,
        }}
      />
    </div>
  )
})
