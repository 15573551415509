import { Objkt } from "Types/entities/Objkt"
import { sum } from "./math"

type TraitsSet = Record<string, number>
type FeaturesSet = Record<string, TraitsSet>

/**
 * Rarity score for tokens within a set, implemented following the openrarity
 * specification
 * https://openrarity.gitbook.io/developers/fundamentals/methodology
 */
export function computeScore(tokens: Objkt[]) {
  // build a set of all the features within the tokens provided
  const set: FeaturesSet = {}
  for (const token of tokens) {
    if (token.features) {
      for (const trait of token.features) {
        const N = trait.name,
          V = trait.value.toString()
        const setTrait = set[N]
        if (!setTrait) {
          set[N] = {
            [V]: 1,
          }
        } else {
          setTrait[V] = V in setTrait ? setTrait[V] + 1 : 1
        }
      }
    }
  }

  // the probability of a trait appearing in the set
  function P_trait(name: string, value: string) {
    return set[name][value] / tokens.length
  }

  // compute the sum of the rarity of the traits for every token (see link)
  const sums: number[] = []
  for (const token of tokens) {
    let sum = 0
    if (token.features) {
      for (const trait of token.features) {
        sum += -Math.log2(P_trait(trait.name, trait.value.toString()))
      }
    }
    sums.push(sum)
  }

  // Expected Value in this case is the average of the scores
  const expectedValue = sums.reduce((a, b) => a + b, 0) / sums.length
  const min = sums.reduce((a, b) => Math.min(a, b), Number.MAX_VALUE)
  const max = sums.reduce((a, b) => Math.max(a, b), Number.MIN_VALUE)

  // now compute the final score, and return objtks with a new rarity score
  return tokens.map((token, idx) => {
    const score = sums[idx] / expectedValue
    // we also compute the fxhash score, a value between 0 and 100
    const fxhashScore = ((sums[idx] - min) / (max - min)) * 1000

    return {
      ...token,
      openRarity: score,
      fxhashRarity: fxhashScore,
    }
  })
}

/**
 * Rarity score for tokens within a set, implemented following the openrarity
 * specification
 * https://openrarity.gitbook.io/developers/fundamentals/methodology
 */
export function computeScoreFxhashNative(tokens: Objkt[]) {
  // build a set of all the features within the tokens provided
  const set: FeaturesSet = {}
  for (const token of tokens) {
    if (token.features) {
      for (const trait of token.features) {
        const N = trait.name,
          V = trait.value.toString()
        const setTrait = set[N]
        if (!setTrait) {
          set[N] = {
            [V]: 1,
          }
        } else {
          setTrait[V] = V in setTrait ? setTrait[V] + 1 : 1
        }
      }
    }
  }

  // the probability of a trait appearing in the set
  function P_trait(name: string, value: string) {
    return set[name][value] / sum(Object.values(set[name]))
  }

  // now compute the final score, and return objtks with a new rarity score
  return tokens.map((token, idx) => {
    let sum = 0
    if (token.features) {
      for (const trait of token.features) {
        sum += P_trait(trait.name, trait.value.toString())
      }
      sum /= token.features.length
    }

    return {
      ...token,
      openRarity: 0,
      fxhashRarity: sum,
    }
  })
}
