import style from "./CyclerRoot.module.scss"
import cs from "classnames"
import { TModeRoot } from "Types/Settings"
import { useFetchProjects } from "Hooks/useFetchProjects"
import { useIndexer } from "Hooks/useIndexer"
import {
  IssuerContractHandler,
  IssuerContractState,
} from "Services/Indexing/ContractHandlers/IssuerHandler"
import { ProjectCycler } from "Components/Viewers/ProjectCycler/ProjectCycler"

interface ISettings {
  project_id: string
  delay: string
}
export const CyclerRoot: TModeRoot<ISettings> = ({ settings }) => {
  // fetches the overall project on fxhash API
  const tokens = useFetchProjects([parseInt(settings.project_id)])
  // make sure no undefined goes through
  const tokensWithoutUndefined = tokens.filter(val => !!val)

  // index whenever we get the data from the backend
  const { data } = useIndexer<IssuerContractState>(
    tokensWithoutUndefined.length > 0,
    {
      address: process.env.REACT_APP_ADDRESS_ISSUER,
      queryParams:
        tokensWithoutUndefined.length > 1
          ? {
              "parameter.issuer_id.in": tokensWithoutUndefined
                .map(tok => tok.id)
                .join(","),
            }
          : {
              "parameter.issuer_id.eq": tokensWithoutUndefined[0]?.id,
            },
    },
    IssuerContractHandler,
    10000
  )

  // safely extract the first token from the response
  const token = tokensWithoutUndefined.length > 0 && tokensWithoutUndefined[0]
  return (
    <div className={cs(style.root)}>
      {token ? (
        <div className={cs(style.project_container)}>
          <ProjectCycler
            mode="default"
            hasKeyboardControls
            delayMs={parseInt(settings.delay)}
            token={token}
            minted={
              (data &&
                data.projects &&
                data.projects[token.id] &&
                data.projects[token.id].mints) ||
              []
            }
          />
        </div>
      ) : (
        <div>Gentk not found</div>
      )}
    </div>
  )
}
