import { ControlBoolean } from "Components/Controls/Boolean"
import { InputText } from "Components/Input/InputText"
import { TControlDefinitions } from "Types/Settings"

export const ControlDefinitions: TControlDefinitions = {
  number: {
    input: InputText,
  },
  string: {
    input: InputText,
  },
  boolean: {
    input: ControlBoolean,
  },
}
