import style from "./RarityBadge.module.scss"
import cs from "classnames"
import { PropsWithChildren } from "react"

type Props = PropsWithChildren<{
  className?: string
}>
export function RarityBadge({ className, children }: Props) {
  return (
    <div className={cs(style.root, className)}>
      <span>{children}</span>
    </div>
  )
}
