import style from "./ModeSelector.module.scss"
import text from "Styles/Text.module.scss"
import cs from "classnames"
import { EViewerMode } from "Types/Settings"
import { SettingsGroup } from "Components/Settings/SettingsGroup"
import { ViewerModeDefinitions, ViewerModes } from "Definitions/Settings"
import { Icon } from "Components/UI/Icon"

interface Props {
  value: EViewerMode | null
  onChange: (mode: EViewerMode | null) => void
}
export function ModeSelector({ value, onChange }: Props) {
  return (
    <SettingsGroup
      title="Viewer mode"
      infos={
        !value
          ? "Select the mode in which you want the live viewer to run."
          : undefined
      }
    >
      <div className={cs(style.select)}>
        {ViewerModes.map(
          mode =>
            ((!!value && mode === value) || !value) && (
              <button
                key={mode}
                type="button"
                className={cs(style.mode, {
                  [style.selected]: mode === value,
                })}
                onClick={() => onChange(mode === value ? null : mode)}
              >
                <div className={cs(style.icon_wrapper)}>
                  {ViewerModeDefinitions[mode].icon}
                </div>
                <div>
                  <strong>{ViewerModeDefinitions[mode].name}</strong>
                  <p className={cs(text.infos, style.description)}>
                    {ViewerModeDefinitions[mode].description}
                  </p>
                </div>
                <Icon
                  icon="fa-sharp fa-solid fa-xmark"
                  className={cs(style.close)}
                />
              </button>
            )
        )}
      </div>
    </SettingsGroup>
  )
}
