import { generateFxHash } from "Utils/fxhash"
import { IMintOperation } from "Services/Indexing/ContractHandlers/IssuerHandler"
import { useEffect, useMemo, useState } from "react"
import { IProjectIteration } from "Types/Project"
import { ProjectView } from "Components/Viewers/ProjectView/ProjectView"

interface ProjectProps {
  delayMs: number
  token: any
  minted: IMintOperation[]
  mode?: "default" | "live-minting"
  hasDebug?: boolean
  hasKeyboardControls?: boolean
}
export function ProjectCycler({
  token,
  minted,
  delayMs,
  hasDebug,
  hasKeyboardControls,
  mode = "default",
}: ProjectProps) {
  const [cursor, setCursor] = useState(0)
  // we generate 10 random hashes which will be used in case of not enough
  // iterations available
  const randomHashes = useMemo(
    () => new Array(10).fill(0).map(_ => generateFxHash()),
    []
  )

  // the array of iterations to display, in the right order
  const [iterations, setIterations] = useState<IProjectIteration[]>([])

  // update the list being displayed
  useEffect(() => {
    // when we build the iterations array, we want the latest iterations to be
    // displayed next. for that purpose, we compare the incoming list with the
    // existing one. if an incoming item is not in the existing list (or if it's
    // in the incoming list but its state is not viewed) and if it
    // was minted less than 20 minutes ago, we add it to the priority queue.
    // otherwise, we just add it to the list.
    // in the end, we insert the priority items just after the cursor.

    // first we create an array with the iterations gathered from the mint ops
    const newIterations: IProjectIteration[] = []
    const priority: IProjectIteration[] = []
    const now = Date.now()

    for (let i = 0; i < minted.length; i++) {
      const op = minted[i]
      // check if it's in the current list
      const existing = iterations.find(item => item.iteration === op.iteration)
      // if it already exists
      if (existing) {
        // check if the state is "not viewed", if so we add it to the priority
        if (!existing.viewed) {
          priority.push(existing)
        }
        // otherwise, we add to the regular queue
        else {
          newIterations.push(existing)
        }
      }
      // if it's not in the current list
      else {
        // we check it it was minted less than 30 minutes ago
        const recent = now - op.timestamp.getTime() < 30 * 3600 * 1000
        // if so, we dd it to the priority queue
        if (recent) {
          priority.push({
            iteration: op.iteration,
            hash: op.hash,
            mintedAt: op.timestamp,
            viewed: false, // force priority if not seen yet
          })
        }
        // otherwise we add it to the regular lisy
        else {
          newIterations.push({
            iteration: op.iteration,
            hash: op.hash,
            mintedAt: op.timestamp,
            viewed: true, // no further priority
          })
        }
      }
    }

    // if there are less than 10 iterations, we add random ones to the list
    // TODO: use artist curation instead
    for (let i = newIterations.length; i < 10; i++) {
      newIterations[i] = {
        iteration: null,
        mintedAt: null,
        hash: randomHashes[i],
        viewed: true,
      }
    }

    // now we force the priority queue where the cursor is
    const final: IProjectIteration[] = []
    for (let i = 0; i <= cursor; i++) {
      final[i] = newIterations[i]
    }
    for (let i = 0; i < priority.length; i++) {
      final[cursor + 1 + i] = priority[i]
    }
    for (let i = cursor + 1; i < newIterations.length; i++) {
      final[priority.length + i] = newIterations[i]
    }

    // we also need to update the cursor to reflect shifts from items just seen
    // the item currently targetted by the cursor
    const activeItemHash = iterations[cursor]?.hash || null
    // only update the cursor if there's an active hash
    if (activeItemHash) {
      // find the actual position of the cursor in the new array
      let npos = cursor
      for (let i = 0; i < final.length; i++) {
        if (final[i].hash === activeItemHash) {
          npos = i
          break
        }
      }
      // and update the cursor if it's different
      if (npos !== cursor) {
        setCursor(npos)
      }
    }

    setIterations(final)
  }, [minted])

  return (
    <ProjectView
      cursor={cursor}
      loop
      onSetCursor={setCursor}
      delayMs={delayMs}
      token={token}
      iterations={iterations}
      mode={mode}
      hasDebug={hasDebug}
      hasKeyboardControls={hasKeyboardControls}
    />
  )
}
