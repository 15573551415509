import { cloneDeep } from "@apollo/client/utilities"
import { User } from "Types/entities/User"
import { getDiffByPath } from "Utils/indexing"
import { ContractIndexingHandler } from "./ContractHandler"

export type TApprovals = Record<string, boolean>

export interface IMintOperation {
  hash: string
  timestamp: Date
  iteration: number
  minterAddress: string
  sender?: User | false
  inputBytes?: string
}

interface IProject {
  mints: IMintOperation[]
}

type TLedgerK = string
type TLedgerV = {
  supply: string
  balance: string
}

export interface IssuerContractState {
  projects: Record<string, IProject>
}

export const IssuerContractHandler: ContractIndexingHandler<IssuerContractState> =
  {
    init: () => {
      return {
        projects: {},
      }
    },

    handlers: {
      // collaborators can make any proposal
      mint: async (op, state) => {
        // the project ID
        const projectID: string = op.parameter.value.issuer_id
        // clone the state
        const nstate = cloneDeep(state)
        // if the project doesn't exist, we create it
        if (!nstate.projects[projectID]) {
          nstate.projects[projectID] = {
            mints: [],
          }
        }
        // get the iteration number from the bigmap diff
        const ledgerDiff = getDiffByPath<TLedgerK, TLedgerV>(op.diffs, "ledger")
        const values = ledgerDiff!.content.value
        const iteration = parseInt(values.supply) - parseInt(values.balance)

        // we add this mint operation to the arrat of mints
        nstate.projects[projectID].mints.push({
          hash: op.hash,
          timestamp: new Date(op.timestamp),
          iteration: iteration,
          minterAddress: op.sender.address,
        })

        return nstate
      },
    },
  }
