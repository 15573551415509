import { SettingsContext } from "Context/SettingsContext"
import { useMemo } from "react"
import { EViewerMode, TModeRoot } from "Types/Settings"
import { parseUrlSettings } from "Utils/Settings"

type Props = {
  mode: EViewerMode
  element: TModeRoot
}
export function ModeRootWrapper(props: Props) {
  // process the settings in the URL
  const settings = useMemo(() => {
    return parseUrlSettings(window.location.search, props.mode)
  }, [])

  return (
    <SettingsContext.Provider value={settings}>
      <props.element settings={settings} />
    </SettingsContext.Provider>
  )
}
