import style from "./GentkInfos.module.scss"
import cs from "classnames"
import { Objkt } from "Types/entities/Objkt"
import { UserBadge } from "Components/User/UserBadge"
import { QRCode } from "Components/Utils/QRCode"
import { GentkFeatures } from "Components/Gentk/GentkFeatures"
import { GenerativeToken } from "Types/entities/GenerativeToken"

interface Props {
  token: GenerativeToken
  gentk: Objkt
  count: number
}
export function GentkInfos({ token, gentk, count }: Props) {
  const url = `${process.env.REACT_APP_FXHASH_WEBSITE}/gentk/${gentk.id}`

  return (
    <div className={cs(style.root)}>
      <div className={cs(style.infos)}>
        <span>
          Edition #{gentk.iteration}/{token.supply}
        </span>
        <span className={cs(style.owned)}>
          <span>Owned by</span>
          <UserBadge
            user={gentk.owner!}
            displayVerified={false}
            displayAvatar={false}
          />
        </span>
      </div>

      <QRCode text={url} size={500} className={cs(style.qr)} />

      <div className={cs(style.features_wrapper)}>
        <GentkFeatures gentk={gentk} maxFeatures={4} />
      </div>
    </div>
  )
}
