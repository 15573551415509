import {
  Controller,
  FxParamControllerProps,
  HTMLInputControllerWithText,
  HTMLInputControllerWithTextInput,
} from "./Controller"
import css from "./Number.module.scss"
import cs from "classnames"
import { useEffect, useMemo, useState } from "react"
import { Slider } from "./Shared/Slider"

export function NumberController(props: FxParamControllerProps<"number">) {
  const { options, value, onChange } = props

  return (
    <Controller
      id={props.id}
      label={props.label}
      layout={props.layout}
      size={props.size}
    >
      <div className={cs(css.root)}>
        <Slider
          value={value}
          onChange={onChange}
          min={options?.min}
          max={options?.max}
          step={options?.step}
          className={cs(css.slider)}
        />
        <div className={cs(css.text)}>{value.toString().slice(0, 12)}</div>
      </div>
    </Controller>
  )
}
