import { useRef, useState } from "react"
import useAsyncEffect from "use-async-effect"
import { ContractIndexingHandler } from "Services/Indexing/ContractHandlers/ContractHandler"
import { Indexer } from "Services/Indexing/Indexer"
import { IIndexContractParams } from "Types/Indexing"
import { ApolloClient, useApolloClient } from "@apollo/client"

export interface IIndexerHookReturn<Result> {
  loading: boolean
  data: Result | null
  error: boolean
  reIndex: () => Promise<void>
}

// a function which takes an array of operations and outputs an array of
// operations of the same length, with eventually some properties of the array
// updated
export type TResultsTransformer<Result> = (
  results: Result,
  client: ApolloClient<object>
) => Promise<Result>

/**
 * A generic-purpose hook providing an interface to interact with an
 * IndexingManager. Lightweight contracts can be indexed on the front easily
 * and it's done by an Indexer instance which queries operations on Tzkt and
 * call a handler to update a global object which is returned when no more
 * operations can be fetched on the contract.
 * @param contractAddress the tezos address of the contract to index
 * @param contractHandler the corresponding indexing manager to process
 * @param refreshInterval how often should the indexer refetch the tzkt API to
 *        get the contract data. If set to false, only indexes once.
 */
export function useIndexer<Result>(
  active: boolean,
  contractParams: IIndexContractParams,
  contractHandler: ContractIndexingHandler<Result>,
  refreshInterval: number | false = false,
  resultsTransformer?: TResultsTransformer<Result>
): IIndexerHookReturn<Result> {
  // true until indexing is fully done
  const [loading, setLoading] = useState<boolean>(true)
  // will be populated with indexing data
  const [data, setData] = useState<Result | null>(null)
  // if there's any error, it's set there
  const [error, setError] = useState<boolean>(false)
  // reference to the indexer instance
  const indexerRef = useRef<Indexer<Result> | null>(null)

  // get the client from apollo context
  const client = useApolloClient()

  // a function to trigger manually the indexer
  const reIndex = async () => {
    if (indexerRef.current) {
      const nres = await indexerRef.current.index()
      if (nres !== data) {
        setData(nres)
      }
    }
  }

  // bootstraps the indexing manager on the contract and processes operations
  useAsyncEffect(
    async isMounted => {
      if (!active) return

      // an utility function which can be used to update the state
      const updateData = (update: Result) => {
        if (isMounted()) {
          setData(update)
          setLoading(false)
        }
      }

      // instanciates an Indexer with the given contract handler
      const indexer = new Indexer(contractParams, contractHandler)
      indexerRef.current = indexer

      // stores the indexing result, mostly in case of a periodic refresh
      let result: Result

      try {
        // first initialized the indexer (fetches the storage if defined in handler)
        await indexer.init()

        // runs the indexing and eventually get some data
        result = await indexer.index()
        if (resultsTransformer) {
          result = await resultsTransformer(result, client)
        }

        // the state can be updated with the output data
        isMounted() && updateData(result)
      } catch (err) {
        console.error(err)
        isMounted() && setError(true)
      }

      // if an interval is set, we request an indexer refresh every now and then
      if (refreshInterval !== false) {
        const interval = setInterval(async () => {
          let nresult = await indexer.index()
          if (resultsTransformer) {
            nresult = await resultsTransformer(nresult, client)
          }
          if (nresult !== result && isMounted()) {
            setData(nresult)
          }
          result = nresult
        }, refreshInterval)

        return () => {
          clearInterval(interval)
        }
      }
    },
    [active]
  )

  return {
    loading,
    data,
    error,
    reIndex,
  }
}
