import React, {
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from "react"
import cs from "classnames"
import style from "./ProjectView.module.scss"
import { TimeoutCounter } from "Components/Utils/TimeoutCounter"
import { RevealIframe } from "Components/Reveal/RevealIframe"
import { ArtworkFrame } from "Components/Artwork/ArtworkFrame"
import { setTimeoutTracked, TimeoutTracked } from "Utils/time"
import { useEventListener } from "Hooks/useEventListener"
import { getLiveUrl } from "Utils/fxhash"
import { UserBadge } from "Components/User/UserBadge"
import { LoaderBlock } from "Components/Layout/LoaderBlock"
import { IProjectIteration } from "Types/Project"
import { SettingsContext } from "Context/SettingsContext"

interface ProjectViewProps {
  delayMs: number
  token: any
  mode: "default" | "live-minting"
  iterations: IProjectIteration[]
  cursor: number
  onSetCursor: (val: number, isEnd: boolean) => void
  loop?: boolean
  hasDebug?: boolean
  hasKeyboardControls?: boolean
}
export const ProjectView = ({
  hasDebug,
  delayMs,
  onSetCursor,
  cursor,
  token,
  iterations,
  mode,
  hasKeyboardControls,
  loop,
}: ProjectViewProps) => {
  const refTimeoutDelay = useRef<TimeoutTracked | null>(null)

  const settings = useContext(SettingsContext)

  const handleMoveCursor = useCallback(() => {
    const isEnd = cursor >= iterations.length - 1
    const c = loop
      ? (cursor + 1) % iterations.length
      : isEnd
        ? cursor
        : cursor + 1
    onSetCursor(c, isEnd)
    iterations[c].viewed = true
    // it's fine to mutate the object in here, but it's a trick hehe
  }, [cursor, iterations, loop, onSetCursor])

  const handleSetTimeoutCursor = useCallback(
    (timeoutDelay: number) => {
      // todo: find a more robust solution, this fixes a bug where the last piece
      // gets stuck at the end of the timer :/
      if (mode === "live-minting") return

      const timeout = refTimeoutDelay.current
      if (timeout) {
        clearTimeout(timeout.timeout)
      }
      refTimeoutDelay.current = setTimeoutTracked(
        handleMoveCursor,
        timeoutDelay
      )
    },
    [handleMoveCursor]
  )

  const handleLoadArtwork = useCallback(
    () => handleSetTimeoutCursor(delayMs),
    [delayMs, handleSetTimeoutCursor]
  )
  const handleErrorArtwork = useCallback(
    () => handleSetTimeoutCursor(1000),
    [handleSetTimeoutCursor]
  )

  useEffect(() => {
    return () => {
      const timeout = refTimeoutDelay.current
      if (timeout) {
        clearTimeout(timeout.timeout)
      }
    }
  }, [])

  useEventListener(
    "keydown",
    event => {
      if (event.code === "Space") {
        const timeout = refTimeoutDelay.current
        if (timeout) {
          clearTimeout(timeout.timeout)
        }
        handleMoveCursor()
      }
    },
    !hasKeyboardControls
  )

  const activeIteration = iterations[cursor]

  const liveUrl = useMemo(
    () =>
      activeIteration &&
      getLiveUrl(token.generativeUri, {
        hash: activeIteration.hash,
        queryParams: settings.display_query_params,
        minterAddress: activeIteration.minterAddress,
        inputBytes: activeIteration.inputBytes,
      }),
    [activeIteration, token.generativeUri, settings.display_query_params]
  )

  return (
    <div className={cs(style.root)}>
      {hasDebug && (
        <div className={style.debug}>
          <p>ID: {token.id}</p>
          {activeIteration && (
            <>
              <p>
                <strong>===Project===</strong>
              </p>
              <p>Hash: {activeIteration.hash}</p>
              <p>Iteration: {activeIteration.iteration}</p>
              <p>Title: {token.name}</p>
              <p>Created by: {token.author.name || token.author.id}</p>
            </>
          )}
          <p>
            <strong>===Settings===</strong>
          </p>
          <p>Delay: {delayMs}ms</p>
          <p>
            <span>Next iteration: </span>
            <TimeoutCounter refTimeout={refTimeoutDelay} refreshEveryMs={100} />
          </p>
          <p>Mode: {mode}</p>
          <p>
            Keyboard controls:{" "}
            {hasKeyboardControls ? (
              <span>
                activated
                <br />
                (Press Space to show next)
              </span>
            ) : (
              "deactivated"
            )}
          </p>
          <p>
            Cursor: {cursor + 1}/{iterations.length} - Is end:{" "}
            {cursor >= iterations.length - 1 ? "yes" : "no"}
          </p>
        </div>
      )}

      {activeIteration ? (
        <>
          <div className={cs(style.frame_wrapper)}>
            {mode === "live-minting" && (
              <RevealIframe
                className={style.reveal_container}
                classNameIframeContainer={style.reveal_iframe_container}
                url={liveUrl}
                onLoaded={handleLoadArtwork}
                resetOnUrlChange={true}
              />
            )}
            {mode === "default" && (
              <ArtworkFrame
                url={liveUrl}
                onLoad={handleLoadArtwork}
                onError={handleErrorArtwork}
              />
            )}
            {hasKeyboardControls && <div className={style.artwork_mask} />}
          </div>

          {/* {mode === 'default' &&
            <div className={cs(style.details)}>
              <div>
                <h3>{token.author.name || token.author.id}</h3>
                <p>Title: {token.name}</p>
                <p>
                  {activeIteration.iteration !== null
                    ? `Claimed`
                    : "Randomly generated"}
                </p>
              </div>
              <div className={cs(style.qr_container)}>
                <div>Learn more about<br />the artist:</div>
                <QRCode
                  text={`${process.env.REACT_APP_FXHASH_WEBSITE}/pkh/${token.author.id}`}
                  className={cs(style.qrcode)}
                />
              </div>
            </div>
          } */}

          {mode === "live-minting" && activeIteration && (
            <div className={style.reveal}>
              <h3>
                {token.name} #{activeIteration.iteration}
              </h3>
              {activeIteration.owner && (
                <UserBadge
                  prependText="minted by"
                  user={activeIteration.owner}
                  size="small"
                  className={cs(style.infos__user_badge)}
                />
              )}
            </div>
          )}
        </>
      ) : (
        <LoaderBlock height="100vh" size="regular">
          <div className={style.scroned_container}>waiting for mint</div>
        </LoaderBlock>
      )}
    </div>
  )
}
