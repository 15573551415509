import { Image } from "Components/Image"
import { RarityBadge } from "Components/Gentk/RarityBadge"
import style from "./IterationWithRarity.module.scss"
import { IterationDisplayProps } from "Components/WallCollection/DisplayVertical"

export function IterationWithRarity({ objkt }: IterationDisplayProps) {
  return (
    <>
      <Image
        alt=""
        image={objkt.captureMedia}
        ipfsUri={objkt.metadata?.thumbnailUri}
        mode="responsive"
      />
      <RarityBadge className={style.rarity_badge}>
        {((objkt.fxhashRarity || 0) * 100).toFixed(2)}%
      </RarityBadge>
    </>
  )
}
